import React from 'react'

const paymentsIcons = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <clipPath id='rkv96vj68a'>
        <path d='M1680 0v2959H0V0h1680z' />
      </clipPath>
      <clipPath id='1l9ormekfb'>
        <path d='M11.367 0a1.799 1.799 0 0 1 1.481 2.813h3.129c.99 0 1.796.806 1.796 1.796 0 .991-.806 1.797-1.796 1.797H14.96c.067.1.126.206.173.32C17.936 7.502 20 10.077 20 13.124a6.648 6.648 0 0 1-6.64 6.64 6.623 6.623 0 0 1-4.85-2.109H3.906c-.99 0-1.797-.806-1.797-1.797 0-.569.267-1.076.68-1.406a1.794 1.794 0 0 1 0-2.812 1.794 1.794 0 0 1 0-2.813 1.794 1.794 0 0 1-.68-1.406c0-.99.806-1.797 1.797-1.797h1.019a1.786 1.786 0 0 1 0-2.031H1.797C.807 3.594 0 2.788 0 1.797 0 .807.806 0 1.797 0h9.57zm1.992 7.266A5.866 5.866 0 0 0 7.5 13.125c0 3.23 2.629 5.86 5.86 5.86 3.23 0 5.859-2.63 5.859-5.86 0-3.23-2.629-5.86-5.86-5.86zm-6.414 7.578H3.906a1.017 1.017 0 0 0 0 2.031h3.976a6.614 6.614 0 0 1-.937-2.031zM11.4 10.9h1.813l.146.007c.348.031.616.176.795.433.175.249.27.607.292 1.075l.004.207v1.248l-.1.1h-.46l-.1-.1V12.6l-.003-.179c-.014-.273-.061-.476-.138-.609-.07-.12-.184-.191-.35-.211l-.105-.006-1.124-.001V15.5l-.1.1h-.57l-.1-.1V11l.1-.1zm4.492 0 .1.1.008 1.185-.003.292c-.02.673-.124 1.226-.313 1.66a2.14 2.14 0 0 1-1.068 1.109c-.43.205-.991.321-1.682.348l-.266.006-.1-.1v-2.951l.1-.1h.46l.1.1-.001 2.293.108-.005c.34-.027.625-.088.857-.181l.133-.06.12-.068c.301-.19.508-.465.622-.83.1-.321.157-.738.168-1.25l.001-.263-.014-1.184.1-.101h.57zM6.81 12.031H3.906a1.017 1.017 0 0 0 0 2.031h2.88a6.668 6.668 0 0 1 .023-2.03zM7.993 9.22H3.906a1.017 1.017 0 0 0 0 2.031H6.99a6.622 6.622 0 0 1 1.004-2.031zm5.484-2.813h-9.57a1.017 1.017 0 0 0 0 2.032H8.66a6.62 6.62 0 0 1 5.26-1.93 1.015 1.015 0 0 0-.443-.102zm2.5-2.812h-9.57a1.017 1.017 0 0 0 0 2.031h9.57a1.017 1.017 0 0 0 0-2.031zM11.367.78h-9.57a1.017 1.017 0 0 0 0 2.031h9.57a1.017 1.017 0 0 0 0-2.03z' />
      </clipPath>
    </defs>
    <g clipPath='url(#rkv96vj68a)' transform='translate(-1393 -1069)'>
      <g clipPath='url(#1l9ormekfb)' transform='translate(1395 1072)'>
        <path fill='#FFF' d='M0 0h20v19.766H0V0z' />
      </g>
    </g>
  </svg>
)

export default paymentsIcons
