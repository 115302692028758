import { styled } from '@mui/material/styles'
import { Card, CardContent, Modal } from '@mui/material'

interface IStyledModalProps {
  isAbsolute?: boolean
  right?: string | number
  left?: string | number
  top?: string | number
  bottom?: string | number
  showBackDrop?: boolean
  width?: string | number
}

interface IStyledCardContent {
  padding?: string | number
}

export const StyledModal = styled(Modal)(
  ({ isAbsolute = false, left, top, right, showBackDrop = true, width }: IStyledModalProps) => ({
    '& .MuiBackdrop-root': {
      backgroundColor: showBackDrop ? '#00000080' : 'transparent'
    },
    '& .MuiPaper-root': {
      position: isAbsolute ? 'absolute' : 'relative',
      top: top ?? 0,
      left: left ?? 0,
      right: right ?? 0,
      ...(isAbsolute && { margin: '0' }),
      ...(width && { width: width })
    }
  })
)

export const StyledCard = styled(Card)`
  & {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 171px;
    border-radius: 12px;
    border: solid 1px #dbdbdb;
    box-shadow: 0 0 4px -1px rgba(167, 169, 172, 0.2), 0 8px 12px -2px rgba(167, 169, 172, 0.16);
  }
`

export const StyledCardContent = styled(CardContent)(({ padding }: IStyledCardContent) => ({
  width: '100%',
  padding: padding ?? '32px 24px'
}))
