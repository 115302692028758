import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import { Accordion, AccordionSummary, Button, styled } from '@mui/material'
import MobileAccordion from '../../UI/MobileAccordion/MobileAccordion'
import AccordionContentCorrespondences from './Correspondences/AccordionContentCorrespondences'
import {
  AccordionWrapper,
  ButtonText,
  DimanicText,
  FlexWrapperTop,
  ItemText,
  Plus,
  SectionTitle
} from '../../../constants/styled.components.constants'
import { Colors } from '../../../constants/styles.constants'
import { theme } from '../../../constants/theme.constants'
import { ROUTES } from '../../../constants/routes.constants'
import Loader from '../../UI/Loader/Loader'
import { isNumber } from 'lodash'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const Appeal = () => {
  const navigate = useNavigate()
  const { width, breakpoint, isMobile } = UseResizeWindow()
  const { setMessages, setCallID, callID } = useChatMessages()
  const { allQuestionsData, loading } = useAppSelector((state) => state.chat)
  const dispatch = useAppDispatch()

  const toNewAppealChat = () => {
    navigate({
      pathname: ROUTES.NEWCHAT
    })
    setCallID(null)
  }
  const handleExistCall = (callID = null) => {
    if (isNumber(callID) && callID >= 0) {
      setCallID(callID)
      navigate({
        pathname: ROUTES.NEWCHAT
      })
    }
  }

  if (loading) {
    return <Loader />
  }

  const topNewestQuestion = allQuestionsData?.slice(0, 3)

  return (
    <>
      {width > breakpoint ? (
        <MainWrapper id='appeal'>
          <FlexContainerBetween>
            <FlexWrapperTop>
              <SectionTitle>פניות </SectionTitle>

              <Button variant='outlined'>
                <Plus>+</Plus>
                <a onClick={toNewAppealChat}> פנייה חדשה</a>
              </Button>
            </FlexWrapperTop>
            {allQuestionsData && allQuestionsData?.length > 0 ? (
              <Link to='onlinechat'>
                <DimanicText>הצגת כל הפניות</DimanicText>
              </Link>
            ) : (
              ''
            )}
          </FlexContainerBetween>

          {topNewestQuestion?.length > 0 ? (
            <WrapperList>
              {topNewestQuestion?.map((item: any, index: any) => (
                <AccordionContentCorrespondences
                  statusID={item.statusID}
                  key={index}
                  callID={item.callID}
                  createDate={item.createDate}
                  descrption={item.descrption}
                  subject={item.subject}
                  handleClick={() => handleExistCall(item.callID)}
                />
              ))}
            </WrapperList>
          ) : (
            <AccordionWrapper className='appeal'>
              <Accordion>
                <AccordionSummary aria-controls='panel1d-content' id='appeal-header'>
                  <AccordionSummaryInner>
                    <DimanicText>
                      {' '}
                      <p>אין מידע רלוונטי</p>
                    </DimanicText>
                  </AccordionSummaryInner>
                </AccordionSummary>
              </Accordion>
            </AccordionWrapper>
          )}
        </MainWrapper>
      ) : (
        <MobileWrapper>
          <MobileAccordion title={'פניות'}>
            <FlexContainerBetween>
              <FlexWrapperTop>
                <Button variant='outlined' onClick={toNewAppealChat}>
                  <Plus>+</Plus>
                  <ButtonText>פנייה חדשה </ButtonText>
                </Button>
              </FlexWrapperTop>
              <Link to='onlinechat'>
                <DimanicText>הצגת כל הפניות</DimanicText>
              </Link>{' '}
            </FlexContainerBetween>
            {allQuestionsData ? (
              <WrapperList>
                {allQuestionsData &&
                  allQuestionsData
                    .slice(0, 3)
                    .map((item: any, index: any) => (
                      <AccordionContentCorrespondences
                        key={index}
                        callID={item?.callID}
                        createDate={item?.createDate}
                        descrption={item?.descrption}
                        subject={item?.subject}
                        statusID={item?.statusID}
                        handleClick={() => handleExistCall(item.callID)}
                      />
                    ))}
              </WrapperList>
            ) : (
              <p>אין מידע רלוונטי</p>
            )}
          </MobileAccordion>
        </MobileWrapper>
      )}
    </>
  )
}

export default Appeal
const MainWrapper = styled('div')({
  padding: '4rem 2rem 0 2rem',
  [theme.breakpoints.down('xl')]: {
    padding: '4rem 1rem 0 1rem'
  }
})
export const WrapperList = styled('div')({
  height: '100%',
  padding: '0 0px',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
})

const FlexContainerBetween = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '0 0 8px 0'
  },
  '& .appeal-event': {
    border: 'none',
    '& p': {
      fontSize: '22px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: Colors.black
    },
    '&:hover': {
      border: `1px solid ${Colors.lightBlue}`,
      '& p:hover': {
        color: Colors.lightBlue
      }
    }
  }
})

const MobileWrapper = styled('div')({
  [theme.breakpoints.down('md')]: {
    display: 'block',

    '&.MuiAccordionDetails-root': {
      padding: '0px 5px 16px'
    },
    '& h2.mobile-view': {
      display: 'none'
    },
    '& .MuiAccordionDetails-root': {
      padding: '10px'
    }
  }
})

const AccordionSummaryInner = styled('div')({
  width: '35%',
  '& : .MuiAccordionSummary-content': {
    display: 'block'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& .mobile-view': {
      display: 'none'
    }
  }
})
