import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'
import _ from 'lodash'

type Props = {
  currentStep?: any
}
function ProgressBorderMobile({ currentStep }: Props) {
  return (
    <BorderBoxWrapper>
      <BorderBox style={{ width: `${currentStep}%` }} className={currentStep ? 'current' : ''}>
        {' '}
        {_.isNumber(currentStep) ? (
          <StepText
            style={{ right: `${currentStep}%` }}
            className={
              currentStep === 100 ? 'endProgress' : currentStep > 0 && currentStep < 100 ? 'progressInProcess' : ''
            }
          >
            <NumberStep
              className={
                currentStep === 100 ? 'endProgress' : currentStep > 0 && currentStep < 100 ? 'progressInProcess' : ''
              }
            >
              {currentStep}%
            </NumberStep>
          </StepText>
        ) : _.isBoolean(currentStep) == true ? (
          ''
        ) : (
          ''
        )}
      </BorderBox>
    </BorderBoxWrapper>
  )
}

export default ProgressBorderMobile

const BorderBoxWrapper = styled('div')({
  height: '14px',
  backgroundColor: Colors.grayBg,
  borderRadius: '10px',
  [theme.breakpoints.down('md')]: {
    height: '4px',
    backgroundColor: Colors.white,
    width: '80%',
    margin: 'auto',
    direction: 'rtl'
  }
})

const BorderBox = styled('section')({
  position: 'relative',
  height: '100%',
  borderRadius: '10px 10px 10px 10px',
  '&.current': {
    backgroundColor: Colors.orengeText,
    [theme.breakpoints.down('md')]: {
      backgroundColor: Colors.blue
    }
  }
})
const StepText = styled('div')({
  textAlign: 'right',
  position: 'absolute',
  top: '0px',
  color: Colors.white,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '300',
  letterSpacing: '1px'
})

const NumberStep = styled('p')({
  position: 'absolute',
  top: -7,
  left: 0,
  right: '50px',
  background: Colors.blue,
  color: Colors.white,
  padding: '2px 12px',
  borderRadius: '8px',
  display: 'inline-table',
  '&.endProgress': {
    top: '-7px',
    right: '-55px',
    background: Colors.blue,
    color: Colors.white,
    padding: '2px 12px',
    borderRadius: '8px'
  }
})
