import React from 'react'
/*comopnents*/
import AccordionTenantsChangesContent from './TenantsChangesContent'
/*styles*/
import { styled } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux.hooks'
import { Colors } from '../../../../constants/styles.constants'
import { FlexWrapperTop, SectionTitle, WrapperList } from '../../../../constants/styled.components.constants'
import { theme } from '../../../../constants/theme.constants'
import appointment from '../../../../../json/appointment.json'

function TenantsChanges() {
  const dispatch = useAppDispatch()
  const tenantsData = useAppSelector((state) => state.finishingMaterials?.changes?.changes)
  const { contractId } = useAppSelector((state) => state.user)
  const { appointmentOfficeData, loading } = useAppSelector((state) => state?.global)

  const dateString = appointment?.data?.future[0]?.from
  const year = dateString.slice(0, 4)
  const month = dateString.slice(4, 6)
  const day = dateString.slice(6, 8)
  const hour = dateString.slice(8, 10)
  const minute = dateString.slice(10, 12)

  const date = new Date(`${year}-${month}-${day}T${hour}:${minute}`)

  const formattedDate = date.toLocaleDateString()
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  // const setAnAppointment = () => {
  //   dispatch(getAppointmentUrl(contractId))
  // }

  return (
    <MainWrapper>
      <FlexWrapperTop className='mobile-view'>
        <SectionTitle>שינויי דיירים</SectionTitle>{' '}
        {/* <a target='_blank' href='https://www.dimri.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/'>
          <Button
            variant='outlined'
            //onClick={setAnAppointment}
          >
            <Plus>+</Plus>
            לקביעת פגישה
          </Button>
        </a> */}
      </FlexWrapperTop>
      <NextMeetingWrapper>
        <NextMeetingInner>
          <a className='link-url' target='_blank' href='https://www.dimri.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/'>
            לקביעת פגישה במשרדי דימרי באתר החברה
          </a>
          {/* <Tooltip title='בלחיצה תועבר לאתר הבית לעמוד צור קשר'>
            <IconButton style={{ padding: '0' }} className='icon-button'>
              <Info />
            </IconButton>
          </Tooltip>
          <InnerContianer>
            <ItemText> פגישה קרובה</ItemText>

            {dateString ? (
              <DimanicText>
                במשרדי דימרי בתאריך {formattedDate} בשעה
                {formattedTime}
              </DimanicText>
            ) : (
              <DimanicText>אין מידע על פגישה קרובה</DimanicText>
            )}
          </InnerContianer> */}
        </NextMeetingInner>
      </NextMeetingWrapper>
      <WrapperList className='srcoll'>
        {tenantsData &&
          tenantsData.map((data: any, index: React.Key | number) => (
            <AccordionTenantsChangesContent
              key={index}
              Date={data?.changesPreview?.Date}
              ChangeEstimateID={data?.changesPreview?.ChangeEstimateID}
              Price={data?.changesPreview?.Price}
              TotalWorkingDays={data?.changesPreview?.TotalWorkingDays}
              changesDetails={data?.changesPreview?.changesDetails}
            />
          ))}
      </WrapperList>
    </MainWrapper>
  )
}

export default TenantsChanges

const MainWrapper = styled('div')({
  padding: '0 1rem 0 0',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
})
const NextMeetingWrapper = styled('div')({
  background: Colors.lightBrownSecond,
  border: `1px solid ${Colors.lightBrownSecond}`,
  width: '60%',
  padding: ' 9px 1rem',
  borderRadius: '8px',
  margin: '0 0 10px 0',
  '&:hover': {
    background: Colors.white
  },
  '& .icon-button:hover': {
    backgroundColor: 'none'
  },
  [theme.breakpoints.down('xl')]: {
    width: '70%'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
})
const InnerContianer = styled('div')({
  padding: '0 2rem'
})

const NextMeetingInner = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minHeight: 50,
  '& a.link-url': {
    color: Colors.darkBrown,
    textDecoration: 'none'
  }
})
const LinkTo = styled('a')({
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '30px',
  textDecoration: 'none',
  cursor: 'pointer',
  color: Colors.black
})
