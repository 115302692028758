import React from 'react'

function LogooutIcon() {
  return (
    <svg width='17' height='18' viewBox='0 0 17 18' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='qe0dse0cza'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='lhilxrdihb'>
          <path d='M9.391 0c.96 0 1.74.78 1.74 1.74v4.173a.348.348 0 0 1-.696 0V1.739c0-.575-.468-1.043-1.044-1.043H1.74c-.575 0-1.043.468-1.043 1.043v12.522c0 .575.468 1.043 1.043 1.043h7.652c.576 0 1.044-.468 1.044-1.043v-4.174a.348.348 0 0 1 .695 0v4.174c0 .958-.78 1.739-1.739 1.739H1.74C.78 16 0 15.22 0 14.26V1.74C0 .78.78 0 1.74 0H9.39zm6.54 8.21-.016.016-.017.021-2.087 2.087a.349.349 0 0 1-.492-.493l1.493-1.494H4.522a.348.348 0 0 1 0-.695H14.81L13.319 6.16a.348.348 0 0 1-.05-.429l.05-.063a.348.348 0 0 1 .492 0l2.076 2.075a.348.348 0 0 1 .093.141l.003.012c.007.017.01.035.013.053l.003.032v.009L16 8a.348.348 0 0 1-.042.165l-.02.032-.008.012z' />
        </clipPath>
        <clipPath id='7zlvfbtm2c'>
          <path d='M17.05-1.05v18.1h-18.1v-18.1h18.1zM9.391 0H1.74C.78 0 0 .78 0 1.74v12.52C0 15.22.78 16 1.74 16H9.39c.96 0 1.74-.78 1.74-1.74v-4.173a.348.348 0 0 0-.696 0v4.174c0 .575-.468 1.043-1.044 1.043H1.74a1.045 1.045 0 0 1-1.043-1.043V1.739c0-.575.468-1.043 1.043-1.043h7.652c.576 0 1.044.468 1.044 1.043v4.174a.348.348 0 0 0 .695 0V1.739C11.13.781 10.35 0 9.391 0zm4.42 5.668a.348.348 0 0 0-.492 0l-.05.063a.348.348 0 0 0 .05.429l1.492 1.492H4.521a.348.348 0 0 0 0 .696l10.291-.001-1.493 1.494a.348.348 0 0 0 .492.493l2.087-2.087.017-.021.015-.017.008-.012.02-.032A.335.335 0 0 0 16 8v-.01l-.001-.01-.003-.03a.271.271 0 0 0-.013-.054l-.003-.012a.348.348 0 0 0-.093-.14z' />
        </clipPath>
      </defs>
      <g clipPath='url(#qe0dse0cza)' transform='translate(-1631 -424)'>
        <g clipPath='url(#lhilxrdihb)' transform='translate(1631.453 425)'>
          <path d='M0 0h16v16H0V0z' />
        </g>
        <g clipPath='url(#7zlvfbtm2c)' transform='translate(1631.453 425)'>
          <path
            d='M9.391 0c.96 0 1.74.78 1.74 1.74v4.173a.348.348 0 0 1-.696 0V1.739c0-.575-.468-1.043-1.044-1.043H1.74c-.575 0-1.043.468-1.043 1.043v12.522c0 .575.468 1.043 1.043 1.043h7.652c.576 0 1.044-.468 1.044-1.043v-4.174a.348.348 0 0 1 .695 0v4.174c0 .958-.78 1.739-1.739 1.739H1.74C.78 16 0 15.22 0 14.26V1.74C0 .78.78 0 1.74 0H9.39zm3.928 5.668a.348.348 0 0 1 .492 0l2.076 2.075a.348.348 0 0 1 .093.141l.003.012c.007.017.01.035.013.053l.003.032L16 8l-.001.02a.35.35 0 0 1-.004.035L16 8a.348.348 0 0 1-.102.247l.045-.056a.35.35 0 0 1-.027.036l-.018.02-2.087 2.087a.349.349 0 0 1-.492-.493l1.493-1.494H4.522a.348.348 0 0 1 0-.695H14.81L13.319 6.16a.348.348 0 0 1-.05-.429z'
            stroke='#FFF'
            strokeWidth='.1'
            fill='none'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  )
}

export default LogooutIcon
