import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from '../../services/ajax.service'
import { API_ENDPOINTS } from '../../constants/api.constant'
import { IQuestionData, servicesType } from '../../constants/interfaces.constant'
import { random } from 'lodash'
import { SassNumber } from 'sass'
export interface IChatState {
  error: IChatError
  draft: {
    messages: {
      text: string | any
    }[]
  }
  postFileData: {
    status: boolean
    ActivityCode: number
    path: string
  }
  loading: boolean
  messages: {
    text: string | any
  }[]
  callID: number | null
  text: string | null
  statusID: any
  isAuth: boolean
  contractId: string | any
  projectId: string | any
  allQuestionsData: servicesType[] | any
  questionData: IQuestionData | null
}
export interface IChatError {
  error: boolean
  errorCode: number | null
  errorMessage: string
}

export const sendInChatFile = createAsyncThunk(
  'user/sendInChatFile',
  ({ contractId, projectId }: Record<string, string | number>, { rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.SEND_FILE, { contractId, projectId })
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const getQuestion = createAsyncThunk('payments/getQuestion', (projectId: string, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.GET_QUESTION, { projectId })
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
)

export const getAllQuestions = createAsyncThunk(
  'user/getAllQuestions',
  ({ contractId, projectId }: Record<string, string | number>, { rejectWithValue }) =>
    API.getInstance()

      .get(API_ENDPOINTS.GET_ALL_QUESTIONS, { contractId, projectId })
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

const initialState: IChatState = {
  draft: {
    messages: []
  },
  isAuth: false,
  error: {
    error: false,
    errorCode: null,
    errorMessage: ''
  },
  loading: false,
  questionData: null,
  allQuestionsData: null,
  messages: [],
  callID: null,
  text: null,
  statusID: null,
  contractId: '',
  projectId: '',
  postFileData: {
    status: false,
    ActivityCode: 0,
    path: ''
    //refetch: undefined
  }
}
export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addNewActiveCall: (state, action) => {
      const { callID, newActivityCall } = action.payload
      console.log('callID', callID, newActivityCall)
      const allQuestionsData = [...state.allQuestionsData]
      const questionsData = allQuestionsData.find((item) => item.callID === callID)
      questionsData.ActivityCalls = [...questionsData.ActivityCalls, newActivityCall]
      state.allQuestionsData = [...allQuestionsData.filter((item) => item.callID !== callID), questionsData]
    },
    clearDraft: (state) => {
      state.draft.messages = []
    },
    setDraft: (state) => {
      state.draft.messages = state.messages
    },
    applyDraft: (state) => {
      state.messages = state.draft.messages
    },
    addMessage: (state, action: any) => {
      const { message } = action.payload
      state.messages.push(message)
    },
    setMessages: (state, action) => {
      state.messages = action.payload.messages
    },

    setCallID: (state, action) => {
      const { callID } = action.payload
      state.callID = callID !== null ? callID : null
    },
    resetCallId: (state) => {
      state.callID = null
    },
    setStatusID: (state, action) => {
      const { statusID } = action.payload
      state.statusID = statusID
    },
    updateMessageText: (state, action) => {
      const { index, newText } = action.payload
      state.messages[index].text = newText
    }
  },
  extraReducers: {
    [sendInChatFile.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [sendInChatFile.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.postFileData = payload
      state.error.error = false
    },
    [sendInChatFile.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
      state.isAuth = false
    },

    [getQuestion.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [getQuestion.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.questionData = payload.reduce((acc: any, item: any) => {
        const catId = acc.findIndex(({ text }: any) => text === item.sugDescription)

        if (catId !== -1) {
          const { subDescription, ...rest } = item
          acc[catId].items.push({ text: subDescription, ...rest })
          return acc
        }

        acc.push({
          id: item?.sugCode,
          empCode: item?.empCode,
          subCode: item?.subCode,
          subDescription: item?.subDescription,
          text: item?.sugDescription,
          items: []
        })
        return acc
      }, [])
      state.error.error = false
    },
    [getQuestion.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
      state.isAuth = false
    },

    [getAllQuestions.pending.type]: (state, action) => {
      state.loading = !action.meta.arg.refetch
      state.error.error = false
    },
    [getAllQuestions.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      const allQuestionsData = payload.services?.map((service: servicesType) => ({
        ...service,
        ID: service.callID,
        ActivityCalls: service?.ActivityCalls?.map(
          ({ url, filename, isFile, statusID, Notes, Details, CntctDate, owner }: any) => ({
            title: Details,
            text: Notes,
            statusID: statusID,
            filename: filename,
            isFile: isFile,
            url: url,
            owner: owner === 0 ? 'user' : 'chat',
            timestamp: new Date(CntctDate).getTime()
          })
        )
      }))
      state.allQuestionsData = [...allQuestionsData].sort(
        (a, b) => new Date(b.createDate as string).getTime() - new Date(a.createDate as string).getTime()
      )
      state.isAuth = true
    },
    [getAllQuestions.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
      state.isAuth = false
    }
  }
})

export const {
  clearDraft,
  resetCallId,
  setStatusID,
  updateMessageText,
  setMessages,
  applyDraft,
  setCallID,
  addMessage,
  setDraft,
  addNewActiveCall
} = chatSlice.actions

export default chatSlice.reducer
