import React, { useState, useMemo } from 'react'
import { useAppSelector } from '../../../hooks/redux.hooks'
import Slider from 'react-slick'
/*components*/
import SecondStep from '../../../assets/images/ProgressImages/SecondStep.png'
import ThirdStep from '../../../assets/images/ProgressImages/ThirdStep.png'
import FourthStep from '../../../assets/images/ProgressImages/FourthStep.png'
import FifthStep from '../../../assets/images/ProgressImages/FifthStep.png'
import { Confirmation } from '../../../constants/icons.constants'
/*styles*/
import { styled } from '@mui/material'
import { Colors } from '../../../constants/styles.constants'
import { theme } from '../../../constants/theme.constants'
import _ from 'lodash'
import TopSection from './TopSection'
import ProgressBorder from './ProgressBorder'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import ProgressBorderMobile from './Mobile/ProgressBorderMobile'
import Loader from '../../UI/Loader/Loader'
import Status1 from '../../../assets/images/ProgressImages/status-1.svg'
import Status2 from '../../../assets/images/ProgressImages/status-2.svg'
import Status3 from '../../../assets/images/ProgressImages/status-3.svg'
import Status4 from '../../../assets/images/ProgressImages/status-4.svg'

function ProgressStages() {
  const { width, breakpoint } = UseResizeWindow()
  const { contractInfoData } = useAppSelector((state) => state.contract)
  const { pogressStatusData, loading } = useAppSelector((state) => state?.finishingMaterials)
  const STEPS = useMemo(
    () => [
      ...(pogressStatusData?.status?.basement
        ? [
            {
              title: 'מרתף',
              imageUrl: FifthStep,
              currentStep: pogressStatusData?.status?.basement
            }
          ]
        : []),
      {
        title: 'תחילת ביצוע',
        imageUrl: Status1,
        currentStep: pogressStatusData?.status?.permit
      },
      {
        title: 'בניית שלד',
        imageUrl: Status2,
        currentStep: pogressStatusData?.status?.structure
      },
      {
        title: 'עבודות גמר',
        imageUrl: Status3,
        currentStep: pogressStatusData?.status?.finishing
      },
      {
        title: 'טופס 4/מסירת דירה',
        imageUrl: Status4,
        currentStep: pogressStatusData?.status?.form4
      }
    ],
    [pogressStatusData?.status]
  )

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleSlideChange = (index: number) => {
    setCurrentSlide(index)
  }

  const settings = {
    // rtl: true,
    initialSlide: currentSlide,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: handleSlideChange
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {width > breakpoint ? (
        <MainWrapper id='progressStages'>
          <TopSection contractInfoData={contractInfoData} />
          <MainWrapperInner>
            <InnerWrapper>
              {STEPS.map(({ currentStep, ...rest }: any) => ({
                currentStep: currentStep === true ? 100 : currentStep === false ? null : currentStep,
                ...rest
              })).map(({ currentStep, title, imageUrl }, index) => (
                <React.Fragment key={index}>
                  <StepProgress className={currentStep > 0 && currentStep < 100 ? 'current-step' : ''}>
                    <ImageBox>
                      <Image
                        className={
                          currentStep === 100 ? 'small' : currentStep > 0 && currentStep < 100 ? 'current' : ''
                        }
                        src={imageUrl}
                        alt={title}
                      />
                    </ImageBox>

                    <ProgressBorder currentStep={currentStep} />

                    <InnerContent className='landscape'>
                      <Dot>
                        {currentStep > 0 && currentStep < 100 && <InnerDot />}
                        {currentStep === 100 && <Confirmation />}
                      </Dot>
                      <TempWrapper>
                        <Text>{title} </Text>
                        <CurrentStepText>
                          שלב {STEPS.length} - {currentStep}
                          {'% '}
                        </CurrentStepText>
                      </TempWrapper>
                    </InnerContent>
                  </StepProgress>
                </React.Fragment>
              ))}
            </InnerWrapper>
          </MainWrapperInner>
        </MainWrapper>
      ) : (
        <MobileWrapper className='progressStagesmb'>
          <TopSection contractInfoData={contractInfoData} />

          <MainWrapperInner>
            <Slider {...settings}>
              {STEPS.map(({ currentStep, ...rest }: any) => ({
                currentStep: currentStep === true ? 100 : currentStep === false ? null : currentStep,
                ...rest
              })).map(({ currentStep, title, imageUrl }, index) => (
                <StepProgress key={index} className={currentStep > 0 && currentStep < 100 ? 'currentstep' : ''}>
                  <ImageBox>
                    <Image
                      className={currentStep === 100 ? 'small' : currentStep > 0 && currentStep < 100 ? 'current' : ''}
                      src={imageUrl}
                      alt={title}
                    />
                  </ImageBox>
                  <MobileBackGroundWrapper>
                    <Text>{title}</Text>
                    <ProgressText>
                      {STEPS.length} שלב {index + 1} מתוך{' '}
                    </ProgressText>
                    <ProgressBorderMobile currentStep={currentStep} />
                  </MobileBackGroundWrapper>
                </StepProgress>
              ))}
            </Slider>
          </MainWrapperInner>
        </MobileWrapper>
      )}
    </>
  )
}

export default ProgressStages
const MainWrapper = styled('div')({
  padding: '4rem 0 3rem 0',
  margin: '0 2rem',
  borderBottom: `2px solid ${Colors.lightBrownSecond}`
})
const MobileBackGroundWrapper = styled('div')({
  background: '#EAF2FA',
  height: '160px',
  padding: '55px 17px 0 17px',
  borderRadius: '6px',
  POSITION: 'ABSOLUTE',
  WIDTH: '100%',
  LEFT: 0,
  RIGHT: 0,
  MARGIN: 'auto'
})
const MainWrapperInner = styled('div')({
  position: 'relative',
  padding: '3rem 0 0 0',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    padding: '1rem 0'
  }
})
const InnerWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})
const StepProgress = styled('div')({
  position: 'relative',
  width: '28%',
  margin: '0 4px',
  '&.current-step': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0'
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0'
  }
})
const ProgressText = styled('span')({
  display: 'block',
  fontSize: '18px',
  fontWeight: '300',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: ' #49382A',
  padding: '0 0 10px 0'
})

const InnerContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0rem',
  margin: '8px 0 0 0'
})
const Dot = styled('span')({
  height: '25px',
  width: '25px',
  backgroundColor: Colors.lightBrownSecond,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
const DotsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row-reverse',
  padding: '18px 0 10px 0',
  position: 'absolute',
  bottom: '18px',
  left: '0',
  right: 0,
  direction: 'initial'
})
const DotMobile = styled('div')({
  height: '15px',
  width: '15px',
  backgroundColor: Colors.white,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 4px'
})
const MobileInnerDot = styled('span')({
  '&.red': {
    background: Colors.red,
    width: '11px',
    height: '11px',
    borderRadius: '50%'
  },
  '&.confrim': {
    position: 'relative',
    background: Colors.black,
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    '&:before': {
      content: '"✓"',
      position: 'absolute',
      top: 2,
      left: 4,
      fontSize: 10,
      transform: 'translate(0px, 0px)',
      color: Colors.white,
      zIndex: 999
    }
  },
  '&.process': {
    width: '9px',
    height: '9px',
    background: Colors.black,
    borderRadius: '50%',
    padding: '0',
    '&.border': {
      position: '',
      border: `1px solid ${Colors.black}`
    }
  }
})

const InnerDot = styled('span')({
  height: '18px',
  width: '18px',
  backgroundColor: Colors.oragne,
  borderRadius: '50%',
  margin: '2px 1px 1px 1px'
})
const Text = styled('p')({
  fontSize: '18px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'right',
  color: '#49382a',
  padding: '0 1rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0'
  }
})
const CurrentStepText = styled('p')({
  display: 'none',
  fontSize: '15px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'right',
  color: Colors.black,
  padding: '0 1rem'
})
const TempWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
})

const MobileWrapper = styled('div')({
  width: '95%',
  margin: '5rem auto 0 auto',
  '& .slick-slider.slick-initialized': {
    '.slick-active.slick-current': {
      width: '100%'
    },
    '.slick-arrow': {
      width: '10px',
      color: 'black!important',
      zIndex: 99,
      top: '65%',

      '&.slick-prev': {
        left: '5px',
        '&:before': {
          content: '"<" !important',
          color: 'black !important',
          fontSize: '15px'
        }
      },
      '&.slick-next': {
        right: '5px',
        left: 'unset !important',
        '&:before ': {
          content: '">" !important',
          color: 'black !important',
          fontSize: '15px'
        }
      }
    }
  }
})
const ImageBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '250px',
  height: '150px',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100px'
  }
})
const Image = styled('img')({
  width: '108px',
  height: '85px',
  margin: 'auto',
  zIndex: '9',
  '&.current': {
    transform: 'scale(1.5)',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(1.5)'
    }
  },
  '&.small': {
    filter: 'grayscale(1)',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(1.5)'
    }
  },
  [theme.breakpoints.down('md')]: {
    transform: 'scale(1.5)',
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    top: '2rem'
  }
})
