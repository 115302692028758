import React, { useContext, useEffect, useState } from 'react'
import { setCallID, setStatusID, resetCallId } from '../../state/features/chat.feature'

import { useAppDispatch, useAppSelector } from '../redux.hooks'
import { ChatContext } from '../../components/Chat/ChatOnLine/context'

export const useChatMessages = () => {
  const {
    messages,
    setMessages,
    clearDraft,
    applyDraft,
    addMessage,
    updateLastMessageText,
    updateMessageText,
    setDraft,
    draft
  } = useContext(ChatContext)

  const { callID, statusID } = useAppSelector((state: { chat: any }) => state.chat)
  const dispatch = useAppDispatch()

  const _setCallID = (callID: number) => {
    dispatch(setCallID({ callID }))
  }

  const _resetCallId = () => {
    dispatch(resetCallId())
  }

  const _setStatusID = (statusID: any) => {
    dispatch(setStatusID({ statusID }))
  }

  return {
    addMessage,
    updateMessageText,
    updateLastMessageText,
    messages,
    setMessages,
    setCallID: _setCallID,
    callID,
    statusID,
    setDraft,
    applyDraft,
    setStatusID: _setStatusID,
    resetCallId: _resetCallId,
    clearDraft,
    draft
  }
}
