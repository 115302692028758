import React from 'react'

function SelectedFinishingMaterialsIcons() {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='d7h9p8pt1a'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='oaokn9ysbb'>
          <path d='M19.5 14v6h-6v-6h6zm-1 1h-4v4h4v-4zm-7 1.5a.5.5 0 0 1 .09.992l-.09.008H.5a.5.5 0 0 1-.09-.992L.5 16.5h11zm8-9.5v6h-6V7h6zm-1 1h-4v4h4V8zm-7 1.5a.5.5 0 0 1 .09.992l-.09.008H.5a.5.5 0 0 1-.09-.992L.5 9.5h11zm8-9.5v6h-6V0h6zm-1 1h-4v4h4V1zm-7 1.5a.5.5 0 0 1 .09.992l-.09.008H.5a.5.5 0 0 1-.09-.992L.5 2.5h11z' />
        </clipPath>
      </defs>
      <g clipPath='url(#d7h9p8pt1a)' transform='translate(-1393 -1733)'>
        <g clipPath='url(#oaokn9ysbb)' transform='translate(1395.55 1735)'>
          <path fill='#443A2C' d='M0 0h19.5v20H0V0z' />
        </g>
      </g>
    </svg>
  )
}

export default SelectedFinishingMaterialsIcons
