import React, { useState, useEffect, useMemo } from 'react'
import { useBlocker } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import { useSearchParams } from 'react-router-dom'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import { useGetTimeMessage } from '../../../hooks/useGetTimeMessage/useGetTimeMessage'
import API from '../../../services/ajax.service'
import { Button, Grid, List, ListItem, ListItemText, styled } from '@mui/material'
import MyAppeals from '../MyAppeals/MyAppeals'
import Category from './Category/Category'
import ChatList from '../ChatSideBar/ChatList'
import Loader from '../../UI/Loader/Loader'
import {
  ChatListInner,
  DimanicText,
  ResponseTitle,
  SmallText,
  TitleFlexWrap
} from '../../../constants/styled.components.constants'
import { theme } from '../../../constants/theme.constants'
import { Colors } from '../../../constants/styles.constants'
import ModalChat from './ModalChat/ModalChat'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import ChatBackgroundSreen from '../ChatBackground/ChatBackgroundSreen'
import { API_ENDPOINTS } from '../../../constants/api.constant'
import { useChatHttp } from '../../../hooks/useChatHttp/useChatHttp'
import { getAllQuestions } from '../../../state/features/chat.feature'

type InitialStateType = {
  title: string | any
  text: JSX.Element | any
  owner: number | string | any
  timestamp: number | any
  subject?: string
}[]

const ChatOnLine = () => {
  const dispatch = useAppDispatch()
  const [showChatList, setShowChatList] = useState(false)
  const { width, breakpoint, isMobile } = UseResizeWindow()
  const { allQuestionsData, loading, questionData } = useAppSelector((state) => state.chat) || []

  const user = useAppSelector((state) => state.user?.user?.info?.username)
  const {
    draft,
    setDraft,
    clearDraft,
    addMessage,
    updateMessageText,
    setMessages,
    setCallID,
    callID,
    statusID,
    setStatusID
  } = useChatMessages()
  const { fileResponse, fileColor, setFileColor, handleMessageCalledActivity, selectedFile, setSelectedFile } =
    useChatHttp()
  const { contractId, projectId } = useAppSelector((state: { user: any }) => state.user)
  const timeStatement = useGetTimeMessage()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isBlock, setIsBlock] = useState<boolean>(false)
  const blocker = useBlocker(!!!callID)
  const [openModal, setOpenModal] = useState(false)
  const [formData, setFormData] = useState<{ text?: string; file?: File }[]>([])

  const [error, setError] = useState<string>('')
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>()
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [visibleForm, setVisibleForm] = useState(false)
  const [answerButton, setAnswerButton] = useState(false)
  const [serviceCallID, setServiceCallID] = useState(null)
  const [selectedOption, setSelectedOption] = useState(false)
  const [selectedID, setSelectedID] = useState(false)

  const [showNewChatMessage, setShowNewChatMessage] = useState<boolean>(false)
  const [listDataChanged, setListDataChanged] = useState(false)
  const [titleName, setTitleName] = useState<any>()

  useMemo

  const groupedNotes = useMemo(() => {
    return formData.map(({ text }) => text).join(' ')
  }, [formData])
  const groupedFiles = useMemo(() => {
    return formData.map(({ file }) => file).filter(Boolean) as File[]
  }, [formData])

  const [lastGroupedNotes, setLastGroupedNotes] = useState('')
  const [lastGroupedFiles, setLastGroupedFiles] = useState<string[]>([])

  const toggleChatView = () => {
    setShowChatList(!showChatList)
  }

  const questionDataTitle = useMemo(() => {
    if (callID) {
      return allQuestionsData?.find((call: any) => call.callID === callID)?.subject
    }
    return callID
      ? allQuestionsData?.length && allQuestionsData[0]?.subject
      : selectedSubCategory
        ? selectedSubCategory?.text
        : 'פנייה חדשה'
  }, [allQuestionsData, selectedSubCategory, callID])

  function getChatStatus(allQuestionsData: any, statusID: any) {
    if (allQuestionsData?.length) {
      switch (allQuestionsData[0].statusID) {
        case -3:
          return 'פתוח'
        case -2:
          return 'בטיפול'
        case -1:
          return 'סגור'
        default:
          return ''
      }
    }
    return ''
  }

  const messageChatStatus = useMemo(() => {
    return getChatStatus(allQuestionsData, statusID)
  }, [allQuestionsData, callID, statusID])

  const handleStepSubject = ({ text }: any) => {
    addMessage({
      text: `בחרת ב${text} כתב/י כעת את נושא הפניה. לתשומת ליבך: ניתן יהיה לצרף קבצים נלווים לאחר קבלת מספר הפניה במערכת.`,
      owner: 'chat',
      timestamp: Date.now(),
      subject: text
    })
  }

  const onCategoryClick = ({ id, text, subCode, empCode, subDescription, items }: any) => {
    setSelectedCategory({ id, text, subCode, empCode, subDescription })

    updateMessageText(0, 'איך נוכל לעזור לך?')
    if (items.length === 0) {
      handleStepSubject({ text })
      setVisibleForm(true)
    } else {
      addMessage({
        title: `${text}`,
        text: (
          <ChatListInner>
            <SmallText>{`בחרת לפנות אלינו לגבי ${text} 👍 בחרֿֿֿ/י כעת תת נושא`}</SmallText>
            <Category
              categoryList={questionData}
              onSubCategoryClick={onSubCategoryClick}
              selectedSubCategory={selectedSubCategory}
              selectedCategory={id}
            />
          </ChatListInner>
        ),
        owner: 'chat',
        timestamp: Date.now(),
        subject: text
      })
    }
  }

  const onSubCategoryClick = (item: any) => {
    const { text } = item
    setSelectedSubCategory(item)
    updateMessageText(
      1,
      `בחרת לפנות אלינו בנושא  ${text} 👍 
        בחר/י כעת תת נושא`
    )
    handleStepSubject({ text })
    setVisibleForm(true)
  }

  const newRequestState: InitialStateType = [
    {
      title: `היי ${user}, ${timeStatement} 👋`,
      text: (
        <ChatListInner>
          <SmallText>איך נוכל לעזור לך?</SmallText>
          <Category
            categoryList={questionData}
            onSubCategoryClick={onSubCategoryClick}
            selectedSubCategory={selectedSubCategory}
            selectedCategory={selectedCategory?.id}
            onCategoryClick={onCategoryClick}
          />
        </ChatListInner>
      ),
      owner: 'chat',
      timestamp: Date.now(),
      subject: ''
    }
  ]

  useEffect(() => {
    if (callID === null) {
      setMessages(newRequestState)
      setCallID(null)
    }
  }, [callID, questionData])

  const handleInputData = (message: { text?: string; file?: File }) => {
    console.log('handleInputData', formData)
    if (!message.text?.trim() && !message.file) {
      setError('שדה זה חייב להכיל תווים/הודעה')
      setTimeout(() => {
        setError('')
      }, 3000)
      return
    }
    setFormData((formData) =>
      formData.concat({
        text: message.text,
        file: message.file
      })
    )
  }

  //  console.log('fileResponse chatonline', fileResponse)

  const submitHandleMessageCalledActivity = async () => {
    try {
      await handleMessageCalledActivity(groupedNotes || '', groupedFiles)
      setLastGroupedNotes(groupedNotes || '')
      console.log('groupedNotes', groupedNotes)
      // const fileNames = groupedFiles.map((file) => file.name)
      // setLastGroupedFiles(fileNames)

      setLastGroupedFiles(groupedFiles as any)
      console.log('groupedFiles', groupedFiles)
    } catch (error) {
      console.log('Error submitting message:', error)
    }
  }

  useEffect(() => {
    if (!groupedNotes.length && !groupedFiles.length) return
    if (groupedNotes) {
      addMessage({
        title: `${user}`,
        text: groupedNotes,
        owner: 'user',
        timestamp: Date.now(),
        subject: ''
      })
    }

    if (callID) {
      submitHandleMessageCalledActivity()
    } else {
      addMessage({
        title: `${selectedSubCategory?.text || selectedCategory?.text}`,
        text: (
          <ChatListInner>
            <MainWrapper>
              <>
                <p>מעולה!🤓 משהו נוסף?</p>
                <List sx={style} component='nav' aria-label='mailbox 2 folders'>
                  <ListItem button>
                    <ListItemText onClick={() => handleClick(0)} primary='כן, אני רוצה להרחיב' />
                  </ListItem>
                  <ListItem button>
                    <ListItemText onClick={() => handleClick(1)} primary='לא, אפשר להתחיל לטפל בפניה' />
                  </ListItem>
                </List>
              </>
            </MainWrapper>
          </ChatListInner>
        ),

        owner: 'chat',
        timestamp: Date.now(),
        subject: ''
      })
    }
    setTimeout(() => setVisibleForm(true), 1200)
  }, [formData])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setOpenModal(true)
    }
  }, [callID, blocker, setOpenModal])

  useEffect(() => {
    setIsBlock(callID === null)
  }, [callID, setIsBlock])

  const clearNote = () => {
    setFormData([])
  }

  const handleClick = async (index: any) => {
    if (callID) {
      const messageData = {
        subDescription: selectedSubCategory?.text || '',
        note: groupedNotes,
        projectId: projectId,
        contractId: contractId,
        serviceCallID: callID
      }
      try {
        const { data } = await API.getInstance().post(API_ENDPOINTS.SEND_ACTIVITY, messageData)
        setAnswerButton(true)
        setIsBlock(false)
        console.log('data', data)
        setFormData([])
      } catch (err) {
        console.log(err)
      }
    } else {
      if (index === 0) {
        setVisibleForm(true)
        addMessage({
          text: 'מה תרצה להוסיף',
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      } else if (index === 1) {
        setSelectedOption(!selectedOption)
        addMessage({
          text: 'ההודעה נשלחה אנא המתן מספר שניות לקבלת מספר פנייה :)',
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })

        const messageData = {
          sugCode: selectedCategory?.id,
          subCode: selectedSubCategory?.subCode || selectedCategory.subCode,
          subDescription: selectedSubCategory?.text || selectedCategory.subDescription,
          note: groupedNotes,
          empCode: selectedSubCategory?.empCode || selectedCategory?.empCode,
          projectId: projectId,
          contractId: contractId
        }
        try {
          const { data } = await API.getInstance().post(API_ENDPOINTS.SEND_QUESTION, messageData)
          addMessage({
            text: (
              <MainWrapper className='show-box'>
                <SmallText> תודה על פנייתך,</SmallText>
                <>
                  {/* <SmallText>פנייה מס’ {data?.serviceCall?.ServiceCallID} הועברה לטיפול</SmallText> */}
                  <SmallText>מספר הפניה במערכת {data?.serviceCall?.ServiceCallID}. פנייתך תטופל בהקדם האפשרי, תודה על הסבלנות. ניתן כעת לצרף קבצים לפניה.</SmallText>
                </>
              </MainWrapper>
            ),
            owner: 'chat',
            timestamp: Date.now(),
            subject: ''
          })
          setServiceCallID(data?.serviceCall?.ServiceCallID)
          setCallID(data?.serviceCall?.ServiceCallID)
          setIsBlock(false)
          clearDraft()
          setFormData([])
          if (data) {
            setShowNewChatMessage(true)
            setListDataChanged(data)
            setTitleName(data?.data?.subject)
            clearNote()
          }
        } catch (err) {
          addMessage({
            title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר',
            owner: 'chat',
            timestamp: Date.now(),
            subject: ''
          })
          console.log(err)
        }
      }
    }
  }

  if (loading) {
    return <Loader />
  }
  return (
    <>
      {openModal ? (
        <ModalChat
          onBlockerReset={() => {
            blocker.reset?.()
            setOpenModal(false)
          }}
          onBlockerproceed={() => {
            blocker.proceed?.()
            setOpenModal(false)
          }}
        />
      ) : (
        ''
      )}
      <Grid
        item
        sx={{
          width: '100%',
          margin: '5rem 0 0 0',
          padding: '0 2rem',
          [theme.breakpoints.down('md')]: {
            padding: '1rem 0 0 0',
            margin: '5rem 0 0 0 !important',
            display: 'block'
          },
          [theme.breakpoints.down('xl')]: {
            margin: '5rem 0 0 0'
          }
        }}
        container
        spacing={3}
      >
        {width > breakpoint ? (
          <MyAppeals showChatList={showChatList} setVisibleForm={setVisibleForm} visibleForm={visibleForm} />
        ) : (
          ''
        )}
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
              width: '95%',
              margin: 'auto'
            }
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              [theme.breakpoints.down('md')]: {
                paddingBottom: '1rem'
              }
            }}
            style={{
              paddingTop: '0'
            }}
          >
            <ChatList
              clearNote={clearNote}
              fileResponse={fileResponse}
              lastGroupedFiles={lastGroupedFiles}
              lastGroupedNotes={lastGroupedNotes}
              onBlockerReset={() => {
                setIsBlock(false)
              }}
              setSelectedFile={setSelectedFile}
              setFileColor={setFileColor}
              setVisibleForm={setVisibleForm}
              serviceCallID={serviceCallID}
              newRequestState={newRequestState}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              allQuestionsData={allQuestionsData}
              loading={loading}
              showChatList={showChatList}
              toggleChatView={toggleChatView}
              setShowChatList={setShowChatList}
              showNewChatMessage={showNewChatMessage}
              listDataChanged={listDataChanged}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              paddingTop: '0'
            }}
          >
            <TitleFlexWrap>
              {isMobile && (
                <Button onClick={toggleChatView} startIcon={<ArrowForwardIosIcon sx={{ fill: '#473B33' }} />} />
              )}

              <DimanicText className='padd-2'>
                {questionDataTitle ? questionDataTitle : titleName}
                {/* {questionDataTitle} */}
              </DimanicText>
              {callID && messageChatStatus && <ResponseTitle>{messageChatStatus} </ResponseTitle>}
            </TitleFlexWrap>

            <ChatBackgroundSreen
              fileColor={fileColor}
              setFileColor={setFileColor}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              allQuestionsData={allQuestionsData}
              callID={callID}
              error={error}
              handleInputData={handleInputData}
              visibleForm={visibleForm}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ChatOnLine

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper'
}

const MainWrapper = styled('div')({
  '&.chatstep': {
    bottom: '6rem'
  },
  '& nav': {
    background: 'none',
    padding: '0',

    '& .MuiListItem-padding.MuiListItem-button': {
      direction: 'rtl',
      textAlign: 'center',
      background: '#fff',
      borderRadius: '10px',
      margin: '4px 0'
    }
  },
  '& hr': {
    display: 'none'
  },
  '& .MuiButtonBase-root': {
    background: Colors.white,
    borderRadius: '10px',
    textAlign: 'right',
    minHeight: '40px',
    height: '40px'
  }
})
