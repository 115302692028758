import React from 'react'

const TenantsChangesIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='qj0wxq9rsa'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='mrhvb8q12b'>
          <path d='M23.333 0c.368 0 .667.298.667.666v22.667a.667.667 0 0 1-.667.667H8.041a.667.667 0 0 1-.666-.667v-9.488H.667A.667.667 0 0 1 0 13.178V.666C0 .298.299 0 .667 0h22.666zm0 .666H.667v12.512h6.981c.217 0 .393.176.393.393v9.762h15.292V.666zm-15.846.667a.41.41 0 0 1 .41.41v1.64h.82v-1.64c0-.226.184-.41.411-.41h13.13a.41.41 0 0 1 .41.41v10.252a.41.41 0 0 1-.41.41h-1.643v.82h1.642a.41.41 0 0 1 .41.41v8.612a.41.41 0 0 1-.41.41h-4.523l-2.436.01a.527.527 0 0 1-.049-.01H9.13a.41.41 0 0 1-.412-.41v-8.611c0-.227.184-.411.411-.411h5.334v-.82H9.128a.411.411 0 0 1-.41-.41v-2.46h-.82v2.46a.41.41 0 0 1-.41.41H1.743a.411.411 0 0 1-.41-.41V1.744c0-.227.183-.411.41-.411h5.743zm-.41.82H2.154v.82h2.051a.41.41 0 0 1 .41.41v6.972a.41.41 0 0 1-.41.41h-2.05v.82h4.922v-2.46a.41.41 0 0 1 .41-.41h1.641a.41.41 0 0 1 .411.41v2.46h5.333a.41.41 0 0 1 .41.41v1.64a.41.41 0 0 1-.41.41H9.54v.82h2.051a.41.41 0 0 1 .41.41v5.332a.41.41 0 0 1-.41.41H9.54v.82h5.343l-.026-5.74a.408.408 0 0 1 .41-.411l4.922-.02a.41.41 0 0 1 .411.409l.02 4.103v.015l.008 1.644h1.218v-7.791h-1.64a.41.41 0 0 1-.41-.41v-1.427L15.866 9.46a.41.41 0 0 1 .471-.672l3.996 2.796h1.512V2.154h-.82v1.23a.41.41 0 0 1-.41.41v2.05a.41.41 0 0 1-.41.41h-1.642a.41.41 0 0 1-.41-.41h-2.462a.41.41 0 0 1-.41.41H13.64a.41.41 0 0 1-.41-.41v-2.05a.41.41 0 0 1-.41-.41v-1.23H9.54V3.65l3.191 3.989a.41.41 0 0 1-.64.512L8.93 4.204H7.486a.41.41 0 0 1-.41-.41v-1.64zm10.265 18.455-1.643.007.006 1.222h1.64l-.003-1.23zm2.46-.01-1.64.007.005 1.23 1.64-.006-.006-1.23zm-8.622-4.912H9.54v4.51h1.64v-4.51zm8.602.802-4.103.016.016 3.29 2.053-.008 2.05-.007-.016-3.291zM3.795 3.794h-1.64v6.15h1.64v-6.15zm11.077 0h-.82v1.64h.82v-1.64zm4.923 0h-.82v1.64h.82v-1.64zm-1.64 0h-2.463v1.23h2.462v-1.23zm2.05-1.64H13.64v.82h6.564v-.82z' />
        </clipPath>
      </defs>
      <g clipPath='url(#qj0wxq9rsa)' transform='translate(-931 -1299)'>
        <g clipPath='url(#mrhvb8q12b)' transform='translate(931 1299)'>
          <path fill='#FFF' d='M0 0h24v24H0V0z' />
        </g>
      </g>
    </svg>
  )
}

export default TenantsChangesIcon
