import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
/*styles*/
import { styled } from '@mui/material/styles'
import { theme } from '../../../../constants/theme.constants'
import { Box } from '@mui/material'

export const AccordionWrapperInner = styled('div')({
  width: '65%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
})

export const DataWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

export const DataWrapperInner = styled('div')({
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: 0
})

export const HeaderAccordion = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  padding: '0 0 1.5rem 0',
  direction: 'rtl'
})

export const AccordionInnerContent = styled('div')({
  width: '25%'
})

export const FooterAccordion = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  direction: 'rtl'
})

export const Description = styled('div')({
  padding: '0 0 1.5rem 0',
  textAlign: 'right'
})

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export const CustomDivider = styled(Box)({
  border: `1px solid #EAF2FA`,
  width: '100%'
})
