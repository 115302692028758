import React from 'react'

function WhatsAppIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='qowapsjzia'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='gamu8y2kcb'>
          <path d='M12 0c6.626 0 12 5.374 12 12s-5.374 12-12 12S0 18.626 0 12 5.374 0 12 0zm.254 4.622c-3.958 0-7.18 3.22-7.182 7.18 0 1.265.33 2.5.959 3.589l-1.02 3.72 3.808-.998a7.18 7.18 0 0 0 3.432.874l.245-.004c3.847-.128 6.939-3.298 6.94-7.176a7.136 7.136 0 0 0-2.101-5.08 7.137 7.137 0 0 0-5.08-2.105zm.003 1.212a5.93 5.93 0 0 1 4.22 1.75 5.93 5.93 0 0 1 1.747 4.222 5.977 5.977 0 0 1-5.97 5.968 5.962 5.962 0 0 1-3.04-.832l-.219-.13-2.259.593.603-2.202-.142-.226a5.953 5.953 0 0 1-.912-3.175 5.978 5.978 0 0 1 5.972-5.968zM9.712 8.486a.66.66 0 0 0-.48.224c-.164.18-.627.614-.627 1.497 0 .883.643 1.736.733 1.856.09.12 1.265 1.931 3.065 2.708.428.185.763.296 1.023.378.43.137.821.118 1.13.071.345-.051 1.062-.434 1.212-.853.15-.419.15-.778.104-.853-.044-.075-.164-.12-.343-.21-.18-.09-1.062-.523-1.227-.583-.164-.06-.284-.09-.403.09-.12.18-.464.584-.569.703-.104.12-.209.135-.388.045-.18-.09-.758-.28-1.444-.89a5.41 5.41 0 0 1-.998-1.243c-.105-.18 0-.268.079-.366.19-.236.389-.494.449-.614s.03-.224-.015-.314-.404-.973-.554-1.332c-.145-.35-.293-.303-.404-.308a7.298 7.298 0 0 0-.343-.006z' />
        </clipPath>
      </defs>
      <g clipPath='url(#qowapsjzia)' transform='translate(-164 -24)'>
        <g clipPath='url(#gamu8y2kcb)' transform='translate(164 24)'>
          <path fill='#FFF' d='M0 0h24v24H0V0z' />
        </g>
      </g>
    </svg>
  )
}

export default WhatsAppIcon
