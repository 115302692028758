import React from 'react'

const info = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <clipPath id='sgnl4ebvia'>
        <path d='M1680 0v2959H0V0h1680z' />
      </clipPath>
      <clipPath id='826l6qw9wb'>
        <path d='m8 0 .25.004A8.001 8.001 0 0 1 16 8l-.004.25A8.002 8.002 0 0 1 8.001 16l-.25-.004A8 8 0 0 1 8.002 0z' />
      </clipPath>
      <clipPath id='6g909e09wc'>
        <path d='M1.979 3.322c.433 0 .78.093 1.04.276l.105.084c.235.209.367.473.397.792l.006.14c0 .063-.006.165-.018.305l-.01.112a3.023 3.023 0 0 1-.058.377l-.045.173-.512 1.815a4.297 4.297 0 0 0-.079.319l-.034.18c-.025.139-.04.254-.047.343l-.003.08c0 .201.037.349.112.443l.05.05c.09.073.234.116.434.128l.127.004c.082 0 .173-.01.27-.03l.153-.036c.117-.032.211-.062.281-.09l.062-.025-.138.56c-.343.135-.628.243-.855.325l-.13.046c-.196.069-.418.11-.665.123l-.19.005c-.436 0-.788-.093-1.054-.28l-.108-.085a1.163 1.163 0 0 1-.408-.789L.655 8.53l.008-.22.023-.227c.013-.1.033-.21.058-.328l.041-.185.516-1.82a9.53 9.53 0 0 0 .082-.337l.034-.158c.02-.104.034-.202.04-.295l.006-.135c0-.185-.03-.325-.092-.421l-.052-.064c-.08-.076-.22-.12-.422-.132l-.129-.004c-.088 0-.178.009-.27.027l-.139.034c-.139.042-.259.08-.359.12l.137-.562c.225-.091.443-.174.655-.248l.313-.105c.23-.073.453-.12.665-.138l.209-.01zM3.118 0c.289 0 .542.082.758.247l.106.09c.204.192.321.419.35.678l.008.132c0 .27-.088.508-.263.71l-.095.098c-.205.19-.446.3-.723.326l-.141.007c-.336 0-.625-.11-.867-.333a1.067 1.067 0 0 1-.354-.675l-.007-.133c0-.27.09-.507.266-.711l.095-.1c.207-.192.45-.302.726-.33L3.117 0z' />
      </clipPath>
    </defs>
    <g clipPath='url(#sgnl4ebvia)' transform='translate(-1230 -1162)'>
      <g clipPath='url(#826l6qw9wb)' transform='translate(1230 1162)'>
        <path fill='#ACACAC' d='M0 0h16v16H0V0z' />
      </g>
      <g clipPath='url(#6g909e09wc)' transform='translate(1235.593 1165.076)'>
        <path fill='#FFF' d='M0 0h4.34v9.821H0V0z' />
      </g>
    </g>
  </svg>
)

export default info
