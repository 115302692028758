import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ItemList, ListMassage, TextDimriBold } from '../../../constants/styled.components.constants'
import ChatSideBar, { ItemText } from './ChatSideBar'
import NewChatMessage from './NewChatMessage'
import Loader from '../../UI/Loader/Loader'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import _ from 'lodash'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import { Box } from '@mui/material'
import MyAppeals from '../MyAppeals/MyAppeals'
import { formatDate } from './dateUtils'
import { servicesType } from '../../../constants/interfaces.constant'

type Props = {
  allQuestionsData?: servicesType[]
  newRequestState?: any
  selectedSubCategory?: string | undefined
  loading?: any
  showChatList?: any
  toggleChatView?: any
  serviceCallID?: any
  setVisibleForm?: any
  setShowChatList?: any
  onBlockerReset(): any | undefined
  setFileColor?: any
  setSelectedFile?: any
  showNewChatMessage?: boolean
  listDataChanged?: any
  setSelectedSubCategory?: any
  lastGroupedNotes?: string
  lastGroupedFiles?: any
  fileResponse?: any
  clearNote(): void
}

function ChatList({
  clearNote,
  fileResponse,
  lastGroupedFiles,
  lastGroupedNotes,
  setSelectedSubCategory,
  setSelectedFile,
  serviceCallID,
  loading,
  allQuestionsData,
  newRequestState,
  selectedSubCategory,
  toggleChatView,
  showChatList,
  setShowChatList,
  setVisibleForm,
  setFileColor,
  showNewChatMessage,
  listDataChanged
}: Props) {
  const {
    addMessage,
    clearDraft,
    callID,
    resetCallId,
    setMessages,
    draft,
    setCallID,
    applyDraft,
    setDraft,
    setStatusID
  } = useChatMessages()
  const current = new Date()
  const navigate = useNavigate()
  const date = formatDate(current)
  const [searchParams, setSearchParams] = useSearchParams()
  const { isMobile } = UseResizeWindow()
  const [sortOrder, setSortOrder] = useState('desc')
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null)
  const [selectedChatIdx, setSelectedChatIdx] = useState<number>(0)

  const createNewActivityCall = () => {
    return {
      callID: listDataChanged?.serviceCall?.ServiceCallID,
      subject: listDataChanged?.data?.subject,
      description: listDataChanged?.data?.note,
      createDate: new Date().toISOString(),
      statusID: listDataChanged?.statusID,
      ActivityCalls: [
        {
          title: `בחרת ב${listDataChanged?.data?.subject} כתב/י כעת את נושא הפניה. לתשומת ליבך: ניתן יהיה לצרף קבצים נלווים לאחר קבלת מספר הפניה במערכת.`,
          owner: 'chat',
          timestamp: new Date().toISOString()
        },
        {
          title: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
          owner: 'chat',
          timestamp: new Date().toISOString()
        },
        {
          title: listDataChanged?.data?.subject,
          text: listDataChanged?.data?.note,
          owner: 'user',
          timestamp: new Date().toISOString()
        },
        {
          title: ` תודה על פנייתך`,
          //text: `  פנייה מס’ ${listDataChanged?.serviceCall?.ServiceCallID} הועברה לטיפול`,
          text: `מספר הפניה במערכת ${listDataChanged?.serviceCall?.ServiceCallID}. פנייתך תטופל בהקדם האפשרי, תודה על הסבלנות. ניתן כעת לצרף קבצים לפניה.`,
          owner: 'chat',
          timestamp: new Date().toISOString()
        }
      ]
    }
  }

  const updateActivityCalls = (chatList: any) => {
    const lastGroupedNotesActivityCall = {
      text: lastGroupedNotes,
      owner: 'user',
      timestamp: new Date().toISOString()
    }

    const updatedChatListCopy = [...chatList]

    const lastGroupedNotesIndex = updatedChatListCopy.findIndex((item) => {
      return item.ActivityCalls && item.ActivityCalls.length > 0
    })

    if (lastGroupedNotesIndex !== -1) {
      const currentActivityCalls = updatedChatListCopy[lastGroupedNotesIndex].ActivityCalls
      const activityCalls = Array.isArray(currentActivityCalls) ? [...currentActivityCalls] : []
      activityCalls.push(lastGroupedNotesActivityCall)
      updatedChatListCopy[lastGroupedNotesIndex] = {
        ...updatedChatListCopy[lastGroupedNotesIndex],
        ActivityCalls: activityCalls
      }
    }

    return updatedChatListCopy
  }

  const chatList = useMemo(() => {
    const data = allQuestionsData || []
    const messagesAndDrafts = data.concat(draft || [])

    let updatedChatList = messagesAndDrafts
      .filter((item) => selectedStatus === null || item.statusID === selectedStatus)
      .sort((a, b) => {
        if (sortOrder === 'asc') {
          return new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        } else {
          return new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
        }
      })

    if (showNewChatMessage) {
      const newActivityCall = createNewActivityCall()
      updatedChatList = [newActivityCall, ...updatedChatList]
    } else if (lastGroupedNotes) {
      updatedChatList = updateActivityCalls(updatedChatList)
    }

    return updatedChatList
  }, [
    allQuestionsData,
    selectedStatus,
    sortOrder,
    newRequestState,
    showNewChatMessage,
    listDataChanged,
    serviceCallID,
    draft,
    lastGroupedNotes
  ])

  const isNewMessageSelected = useMemo(() => selectedChatIdx === 0, [selectedChatIdx])

  useEffect(() => {
    const services = allQuestionsData || []
    let questionIndex
    if (services.length === 0 || (callID && selectedChatIdx! > 0)) {
      setSelectedChatIdx(0)
      return
    }
    let firstMessages
    if (!_.isNil(callID) && callID >= 0) {
      questionIndex = allQuestionsData?.findIndex((question: any, idx: number) => question.callID === callID)
    }
    if (_.isNil(questionIndex)) {
      questionIndex = 0
    }
    if (allQuestionsData) {
      firstMessages = allQuestionsData[questionIndex]
      setSelectedChatIdx(questionIndex)
      setMessages(firstMessages?.ActivityCalls as [])
      setCallID(firstMessages?.callID as number)
    }
    setStatusID(firstMessages?.statusID)
  }, [allQuestionsData])

  const onExisitsMessageItemClicked = (item: any, index: number) => {
    const messages = item.ActivityCalls || item.messages
    clearNote()
    setSelectedFile(null)
    setFileColor('')
    if (callID === null) {
      setDraft()
    }
    setSelectedChatIdx(index + 1)
    setCallID(item.callID)
    setStatusID(item.statusID)
    setMessages(messages)
  }

  const onNewMessage = () => {
    if (draft && draft.messages.length > 0) {
      setSelectedSubCategory('פנייה חדשה')
      resetCallId()
      applyDraft()
      //setDraft()
    } else {
      setMessages(newRequestState)
    }
    setSelectedChatIdx(0)
    if (window.innerWidth <= 900) {
      setShowChatList(!showChatList)
      setSelectedSubCategory('פנייה חדשה')
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Box
      className={isMobile ? 'chat-mobile-view' : ''}
      style={showChatList && isMobile ? { transform: 'translateX(100%)' } : {}}
    >
      {isMobile && (
        <MyAppeals
          showChatList={showChatList}
          setShowChatList={setShowChatList}
          toggleChatView={toggleChatView}
          newRequestState={newRequestState}
          setVisibleForm={setVisibleForm}
        />
      )}

      <ChatSideBar
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        allQuestionsData={chatList}
      />

      <ListMassage className={callID ? 'remove msg-scroll' : 'msg-scroll'}>
        {callID === null && (
          <NewChatMessage
            serviceCallID={serviceCallID}
            onNewMessage={onNewMessage}
            selectedSubCategory={selectedSubCategory}
            callID={callID}
            isSelected={isNewMessageSelected}
            date={date}
          />
        )}

        {chatList?.length > 0 ? (
          chatList?.map((item: any, index: any) => (
            <React.Fragment key={index}>
              {item?.callID === callID}
              <ItemList
                className={item?.callID === callID && callID !== null ? 'firstMsg' : ''}
                onClick={() => {
                  onExisitsMessageItemClicked(item, index)
                  toggleChatView(!showChatList)
                }}
              >
                <div className='inner'>
                  <TextDimriBold>{item?.subject || selectedSubCategory?.text || 'פנייה חדשה'}</TextDimriBold>
                  <ItemText className='appeal-num'>מספר פנייה {item?.callID}</ItemText>
                  <ItemText className='pad-12'>
                    {item?.statusID === -3
                      ? ' בטיפול'
                      : item?.statusID === -1
                        ? 'סגור'
                        : item?.statusID === -2
                          ? 'פתוח'
                          : 'טיוטה'}
                  </ItemText>
                </div>
                {/* <ItemText>{new Date(item?.createDate).toLocaleDateString()}</ItemText> */}
                <ItemText>
                  {new Date(item?.createDate)
                    .toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' })
                    .replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3')}
                </ItemText>
              </ItemList>
            </React.Fragment>
          ))
        ) : (
          <p
            style={{
              padding: '2rem',
              textAlign: 'center',
              fontSize: '30px'
            }}
          >
            אין הודעות
          </p>
        )}
      </ListMassage>
    </Box>
  )
}

export default ChatList

// const chatList = useMemo(() => {
//   const data = allQuestionsData || []
//   const messagesAndDrafts = data.concat(draft || [])

//   let updatedChatList = messagesAndDrafts
//     .filter((item: any) => selectedStatus === null || item.statusID === selectedStatus)
//     .sort((a: any, b: any) => {
//       if (sortOrder === 'asc') {
//         return new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
//       } else {
//         return new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
//       }
//     })

//   if (showNewChatMessage) {
//     const newActivityCall: servicesType = {
//       callID: listDataChanged?.serviceCall?.ServiceCallID,
//       subject: listDataChanged?.data?.subject,
//       descrption: listDataChanged?.data?.note,
//       createDate: new Date().toISOString(),
//       statusID: listDataChanged?.statusID,
//       ActivityCalls: [
//         {
//           title: `בחרת ${listDataChanged?.data?.subject}. כתב/י כעת את נושא הפניה`,
//           owner: 'chat',
//           timestamp: new Date().toISOString()
//         },
//         {
//           title: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
//           owner: 'chat',
//           timestamp: new Date().toISOString()
//         },
//         {
//           title: listDataChanged?.data?.subject,
//           text: listDataChanged?.data?.note,
//           owner: 'user',
//           timestamp: new Date().toISOString()
//         },
//         {
//           title: ` תודה על פנייתך`,
//           text: `  פנייה מס’ ${listDataChanged?.serviceCall?.ServiceCallID} הועברה לטיפול`,
//           owner: 'chat',
//           timestamp: new Date().toISOString()
//         }
//       ]
//     }

//     updatedChatList = [newActivityCall, ...updatedChatList]
//   } else if (lastGroupedNotes) {
//     const lastGroupedNotesActivityCall: servicesType = {
//       text: lastGroupedNotes,
//       owner: 'user',
//       timestamp: new Date().toISOString()
//     }

//     const updatedChatListCopy = [...updatedChatList] // Create a copy of updatedChatList

//     const lastGroupedNotesIndex = updatedChatListCopy.findIndex((item) => {
//       return item.ActivityCalls && item.ActivityCalls.length > 0
//     })

//     if (lastGroupedNotesIndex !== -1) {
//       const currentActivityCalls = updatedChatListCopy[lastGroupedNotesIndex].ActivityCalls
//       const activityCalls = Array.isArray(currentActivityCalls) ? [...currentActivityCalls] : []
//       activityCalls.push(lastGroupedNotesActivityCall) // Push the new activity call to the array
//       updatedChatListCopy[lastGroupedNotesIndex] = {
//         ...updatedChatListCopy[lastGroupedNotesIndex], // Copy other properties from the existing object
//         ActivityCalls: activityCalls // Assign the updated array back to ActivityCalls
//       }
//     }

//     updatedChatList = updatedChatListCopy // Assign the modified copy back to updatedChatList
//   }

//   return updatedChatList
// }, [
//   allQuestionsData,
//   selectedStatus,
//   sortOrder,
//   newRequestState,
//   showNewChatMessage,
//   listDataChanged,
//   serviceCallID,
//   draft,
//   lastGroupedNotes
// ])
