import React from 'react'

function KitchenIcon() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='ckvwy7py9a'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='emibnhlsub'>
          <path d='M408 20c13.807 0 25 11.193 25 25v542c0 13.807-11.193 25-25 25H25c-13.807 0-25-11.193-25-25V45c0-13.807 11.193-25 25-25h383z' />
        </clipPath>
        <clipPath id='js226kmpyc'>
          <path d='M28 0a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24z' />
        </clipPath>
        <clipPath id='t37bk5vjcd'>
          <path d='M2.879 0c.792 0 1.528.4 2.074 1.125.519.69.804 1.6.804 2.564 0 .963-.285 1.874-.804 2.563a2.903 2.903 0 0 1-1.065.895l.61 9.129A1.62 1.62 0 0 1 2.877 18a1.62 1.62 0 0 1-1.619-1.725l.61-9.128a2.903 2.903 0 0 1-1.064-.895C.285 5.562 0 4.652 0 3.688c0-.963.286-1.874.805-2.563C1.35.399 2.087 0 2.879 0zm8.83 0c.228 0 .414.185.414.413v5.976c0 .673-.55 1.22-1.225 1.22h-.073l.58 8.707A1.581 1.581 0 0 1 9.826 18a1.58 1.58 0 0 1-1.58-1.684l.58-8.707h-.073a1.224 1.224 0 0 1-1.225-1.22V.413a.414.414 0 0 1 .829 0v4.69H9.41V.413a.414.414 0 0 1 .828 0v4.69h1.055V.413c0-.228.186-.413.415-.413zm5.659.062a.415.415 0 0 1 .628.404L18 10.756v6.003a1.239 1.239 0 0 1-2.478 0v-5.59a.878.878 0 0 1-.853-.876V4.887l.006-.247A5.631 5.631 0 0 1 17.368.062zM3.073 7.368a2.344 2.344 0 0 1-.389 0l-.598 8.962c-.014.221.061.432.213.594.152.161.358.25.58.25a.787.787 0 0 0 .58-.25.782.782 0 0 0 .212-.594zm6.921.241h-.339l-.584 8.762c-.014.21.057.41.202.564a.75.75 0 0 0 .552.24.75.75 0 0 0 .552-.24.744.744 0 0 0 .202-.564l-.585-8.762zm7.178 3.56h-.822v5.59a.41.41 0 0 0 .822 0v-5.59zm0-9.943a4.805 4.805 0 0 0-1.674 3.661v5.406c0 .028.022.051.05.051h1.624zm-5.878 4.702H8.356v.46c0 .219.177.396.396.396h2.146a.396.396 0 0 0 .396-.395v-.46zM2.88.826C1.749.826.829 2.11.829 3.688c0 1.354.676 2.49 1.582 2.787a1.497 1.497 0 0 0 .468.076c1.13 0 2.05-1.284 2.05-2.863C4.93 2.11 4.01.826 2.88.826z' />
        </clipPath>
        <clipPath id='klqxspytje'>
          <path d='M19.05-1.05v20.1h-20.1v-20.1h20.1zM2.879 0C2.087 0 1.35.4.805 1.125.285 1.815 0 2.725 0 3.688c0 .964.286 1.875.805 2.564.303.404.666.706 1.064.895l-.609 9.129A1.62 1.62 0 0 0 2.88 18a1.62 1.62 0 0 0 1.618-1.724l-.609-9.129c.399-.189.761-.491 1.065-.895.519-.69.804-1.6.804-2.563 0-.964-.285-1.875-.804-2.564C4.407.399 3.67 0 2.879 0zm8.83 0a.414.414 0 0 0-.415.413v4.69H10.24V.413a.414.414 0 0 0-.828 0v4.69H8.356V.413a.414.414 0 0 0-.829 0v5.976c0 .673.55 1.22 1.225 1.22h.073l-.58 8.707A1.58 1.58 0 0 0 9.824 18a1.581 1.581 0 0 0 1.58-1.684l-.58-8.707h.073c.676 0 1.225-.547 1.225-1.22V.413A.414.414 0 0 0 11.709 0zm6.23.196a.415.415 0 0 0-.571-.134 5.631 5.631 0 0 0-2.693 4.578l-.006.247v5.406c0 .475.38.861.853.875v5.591a1.239 1.239 0 0 0 2.478 0v-6.003L17.996.466a.41.41 0 0 0-.058-.27zM3.072 7.368l.598 8.962a.782.782 0 0 1-.213.594.787.787 0 0 1-.58.25.788.788 0 0 1-.579-.25.783.783 0 0 1-.213-.594l.598-8.962a2.33 2.33 0 0 0 .389 0zm6.921.241.585 8.762a.744.744 0 0 1-.202.564.75.75 0 0 1-.552.24.75.75 0 0 1-.552-.24.744.744 0 0 1-.202-.564l.584-8.762h.34zm7.178 3.56v5.59a.41.41 0 0 1-.822 0v-5.59h.822zm0-9.943v9.118h-1.623a.05.05 0 0 1-.051-.05V4.886c0-1.428.614-2.749 1.674-3.66zm-5.878 4.702v.46a.396.396 0 0 1-.396.396H8.752a.396.396 0 0 1-.396-.395v-.46h2.938zM2.88.826c1.13 0 2.05 1.284 2.05 2.862 0 1.579-.92 2.863-2.05 2.863a1.497 1.497 0 0 1-.468-.076C1.505 6.18.828 5.042.828 3.688c0-1.578.92-2.862 2.05-2.862z' />
        </clipPath>
      </defs>
      <g clipPath='url(#ckvwy7py9a)' transform='translate(-1385 -1421)'>
        <g clipPath='url(#emibnhlsub)' transform='translate(1004 1281)'>
          <g clipPath='url(#js226kmpyc)' transform='translate(381 140)'>
            <path fill='#F79780' d='M0 0h32v32H0V0z' />
          </g>
          <g clipPath='url(#t37bk5vjcd)' transform='translate(388 147)'>
            <path fill='#FFF' d='M0 0h18v18H0V0z' />
          </g>
          <g clipPath='url(#klqxspytje)' transform='translate(388 147)'>
            <path
              d='M4.953 1.125C4.407.399 3.67 0 2.879 0 2.087 0 1.35.4.805 1.125.285 1.815 0 2.725 0 3.688c0 .964.286 1.875.805 2.564.303.404.666.706 1.064.895l-.609 9.129A1.62 1.62 0 0 0 2.88 18a1.62 1.62 0 0 0 1.618-1.724l-.609-9.129c.399-.189.761-.491 1.065-.895.519-.69.804-1.6.804-2.563 0-.964-.285-1.875-.804-2.564zM3.458 16.924a.787.787 0 0 1-.58.25.788.788 0 0 1-.579-.25.783.783 0 0 1-.213-.594l.598-8.962a2.33 2.33 0 0 0 .389 0l.598 8.962a.782.782 0 0 1-.213.594zM2.878 6.55a1.497 1.497 0 0 1-.467-.076C1.505 6.18.828 5.042.828 3.688c0-1.578.92-2.862 2.05-2.862C4.01.826 4.93 2.11 4.93 3.688c0 1.579-.92 2.863-2.05 2.863zM18 10.755V.465l-.004.001a.415.415 0 0 0-.628-.405 5.633 5.633 0 0 0-2.699 4.825v5.407c0 .475.38.861.853.875v5.591a1.239 1.239 0 0 0 2.478 0v-6.004zm-2.451-.41a.05.05 0 0 1-.051-.052V4.888c0-1.429.614-2.75 1.674-3.662v9.118h-1.623zm1.623 6.414a.41.41 0 0 1-.822 0v-5.59h.822v5.59zM11.709 0a.414.414 0 0 0-.415.413v4.69H10.24V.413a.414.414 0 0 0-.828 0v4.69H8.356V.413a.414.414 0 0 0-.829 0v5.976c0 .673.55 1.22 1.225 1.22h.073l-.58 8.707A1.58 1.58 0 0 0 9.824 18a1.581 1.581 0 0 0 1.58-1.684l-.58-8.707h.073c.676 0 1.225-.547 1.225-1.22V.413A.414.414 0 0 0 11.709 0zm-1.332 16.935a.75.75 0 0 1-.552.24.75.75 0 0 1-.552-.24.744.744 0 0 1-.202-.564l.584-8.762h.34l.584 8.762a.744.744 0 0 1-.202.564zm.917-10.546a.396.396 0 0 1-.396.395H8.752a.396.396 0 0 1-.396-.395v-.46h2.938v.46z'
              stroke='#FFF'
              strokeWidth='.1'
              fill='none'
              strokeLinejoin='round'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default KitchenIcon
