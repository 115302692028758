import React from 'react'

function ParkingIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='0da8ivsmta'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='cxciq6d49b'>
          <path d='M20.281.86H1.72a.86.86 0 0 0-.86.859V20.28c0 .474.386.86.86.86h7.347l.061.005.045-.005h11.11a.86.86 0 0 0 .859-.86V1.72a.86.86 0 0 0-.86-.86zm-8.636 3.265a4.947 4.947 0 0 1 4.94 4.941 4.947 4.947 0 0 1-4.94 4.942H8.852v3.437a.43.43 0 0 1-.43.43H5.844a.43.43 0 0 1-.43-.43V4.555a.43.43 0 0 1 .43-.43h5.8zm0 .86H6.273v12.03h1.72v-3.437a.43.43 0 0 1 .429-.43h3.223a4.087 4.087 0 0 0 4.082-4.082 4.087 4.087 0 0 0-4.082-4.082zm0 1.718a2.366 2.366 0 0 1 2.363 2.363 2.366 2.366 0 0 1-2.363 2.364H8.422a.43.43 0 0 1-.43-.43V7.133a.43.43 0 0 1 .43-.43h3.223zm0 .86H8.852v3.007h2.793c.829 0 1.503-.674 1.503-1.504 0-.829-.674-1.504-1.503-1.504zM9.066 22H1.72A1.72 1.72 0 0 1 0 20.281V1.72A1.72 1.72 0 0 1 1.719 0H20.28A1.72 1.72 0 0 1 22 1.719V20.28A1.72 1.72 0 0 1 20.281 22H9.066z' />
        </clipPath>
      </defs>
      <g clipPath='url(#0da8ivsmta)' transform='translate(-1393 -2420)'>
        <g clipPath='url(#cxciq6d49b)' transform='translate(1394 2421)'>
          <path fill='#443A2C' d='M0 0h22v22H0V0z' />
        </g>
      </g>
    </svg>
  )
}

export default ParkingIcon
