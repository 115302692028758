import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../../../hooks/redux.hooks'
/*components*/
import Loader from '../../../UI/Loader/Loader'
import { ChevronLeft, GreenPaymentConfirmation, Info } from '../../../../constants/icons.constants'
/*styles*/
import { Text } from '../../../../constants/styled.components.constants'
import { Box, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  AmountSubTitle,
  AmountTitle,
  CardText,
  CustomDivider,
  DetailButton,
  InnerWrapper,
  PaymentAmountResult,
  PaymentDetailsCardStaticWrapper,
  PaymentMoreDetailsCardStaticWrapper,
  SubTitle
} from './NextPaymentStyle'
import CloseIcon from '../../../../assets/icons/Close.icon'

function NextPayment() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { nextListPaymentData, amountPaidData, futurePaymentList, loading } = useAppSelector((state) => state.payments)
  const tooltips = useAppSelector((state) => state.global?.tooltipsList)
  const [openTooltipKey, setOpenTooltipKey] = useState<string | null>(null)
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<number | null>(null)
  //TODO: check if needed?
  // const allPayments = [...nextListPaymentData?.paymentsList, { next: true, ...nextListPaymentData?.nextPayment }]

  const allPaymentListData = useMemo(() => {
    const parsedFuturePaymentListToAmountPaidData = futurePaymentList.map((futurePayment) => ({
      receptionNumber: '-',
      date: futurePayment.paymentDate,
      fund: futurePayment.amount,
      linkage: futurePayment.linkage,
      interest: '0',
      vat: '0',
      totalPayment: futurePayment.amount,
      paidFor: futurePayment.futurePaymentFor
    }))
    return [...amountPaidData, ...parsedFuturePaymentListToAmountPaidData]
  }, [amountPaidData, futurePaymentList])

  const getTooltipTextValueByKey = (tooltipKey: string) => {
    return tooltips?.find((tooltip) => tooltip.key === tooltipKey)?.value || ''
  }

  const handleTooltipToggle = (tooltipKey: string) => {
    setOpenTooltipKey((prevKey) => (prevKey === tooltipKey ? null : tooltipKey))
  }

  const handleTooltipOpen = (tooltipKey: string) => {
    setOpenTooltipKey(tooltipKey)
  }

  const handleTooltipClose = () => {
    setOpenTooltipKey(null)
  }

  const handleDetailClick = (index: number) => {
    setSelectedPaymentIndex(index)
  }

  const [visibleIndex, setVisibleIndex] = useState(0)

  const handlePrevClick = () => {
    setSelectedPaymentIndex(null)
    setVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleNextClick = () => {
    setSelectedPaymentIndex(null)
    allPaymentListData.length && setVisibleIndex((prevIndex) => Math.min(prevIndex + 1, allPaymentListData.length - 1))
  }

  if (loading) {
    return <Loader />
  }

  return (
    <InnerWrapper>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        width='100%'
        sx={{
          alignItems: isMobile ? 'center' : 'none',
          overflow: isMobile ? 'auto' : 'none',
          maxHeight: isMobile ? '850px' : 'none'
        }}
      >
        {allPaymentListData
          .slice(visibleIndex, isMobile ? allPaymentListData.length : visibleIndex + 6)
          .map((paymentItem, index) => (
            <React.Fragment key={index}>
              {selectedPaymentIndex === index ? (
                <Box position='relative' display='flex' justifyContent='center' paddingTop={isMobile ? '40px' : 'none'}>
                  <Box
                    height='80px'
                    width='152px'
                    position='absolute'
                    zIndex={1}
                    bottom={244}
                    right={isMobile ? 89 : 59}
                    borderRadius='8px'
                    border='1px solid #C2D4DF'
                    sx={{ background: 'white' }}
                  >
                    <Stack
                      justifyContent={paymentItem.receptionNumber === '-' ? 'center' : 'none'}
                      direction={'row'}
                      paddingTop='12px'
                      paddingRight={paymentItem.receptionNumber === '-' ? 'none' : '16px'}
                    >
                      {paymentItem.date === '01.01.1900' ? null : paymentItem.receptionNumber === '-' ? null : (
                        <GreenPaymentConfirmation />
                      )}
                      <Text>{paymentItem.date === '01.01.1900' ? 'יתרת חוב' : paymentItem.date}</Text>
                    </Stack>
                    <SubTitle>{paymentItem?.paidFor}</SubTitle>
                  </Box>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      left: isMobile ? '24px' : '14px',
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                    onClick={() => setSelectedPaymentIndex(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <PaymentMoreDetailsCardStaticWrapper>
                    <Stack direction='column'>
                      <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' alignItems='center'>
                          <CardText>סכום קרן</CardText>
                          <Tooltip
                            dir='rtl'
                            disableTouchListener
                            open={openTooltipKey === 'receipt_total_amount'}
                            onClose={() => setOpenTooltipKey(null)}
                            title={getTooltipTextValueByKey('receipt_total_amount')}
                            onMouseEnter={() => handleTooltipOpen('receipt_total_amount')}
                            onMouseLeave={handleTooltipClose}
                            onClick={() => handleTooltipToggle('receipt_total_amount')}
                            onMouseDown={(e) => {
                              e.preventDefault()
                              handleTooltipToggle('receipt_total_amount')
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  marginTop: '0px !important',
                                  maxWidth: '200px'
                                }
                              }
                            }}
                          >
                            <IconButton style={{ width: '24px', height: '24px', padding: '0px', marginRight: '5px' }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <CardText>{`₪${
                          (paymentItem.fund && parseFloat(paymentItem.fund).toLocaleString()) || '-'
                        }`}</CardText>
                      </Stack>
                      <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' alignItems='center'>
                          <CardText>ריבית</CardText>
                          <Tooltip
                            dir='rtl'
                            disableTouchListener
                            title={getTooltipTextValueByKey('property_acquisition_interest')}
                            open={openTooltipKey === 'property_acquisition_interest'}
                            onClose={() => setOpenTooltipKey(null)}
                            onMouseEnter={() => handleTooltipOpen('property_acquisition_interest')}
                            onMouseLeave={handleTooltipClose}
                            onClick={() => handleTooltipToggle('property_acquisition_interest')}
                            onMouseDown={(e) => {
                              e.preventDefault()
                              handleTooltipToggle('property_acquisition_interest')
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  marginTop: '0px !important',
                                  maxWidth: '200px'
                                }
                              }
                            }}
                          >
                            <IconButton style={{ width: '24px', height: '24px', padding: '0px', marginRight: '5px' }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <CardText>{`₪${
                          (paymentItem.interest && parseFloat(paymentItem.interest).toLocaleString()) || '-'
                        }`}</CardText>
                      </Stack>
                      <Stack direction='row' justifyContent='space-between' paddingBottom='17px'>
                        <Stack direction='row' alignItems='center'>
                          <CardText>הצמדה</CardText>
                          <Tooltip
                            dir='rtl'
                            disableTouchListener
                            title={getTooltipTextValueByKey('property_acquisition_linkage')}
                            open={openTooltipKey === 'property_acquisition_linkage'}
                            onClose={() => setOpenTooltipKey(null)}
                            onMouseEnter={() => handleTooltipOpen('property_acquisition_linkage')}
                            onMouseLeave={handleTooltipClose}
                            onClick={() => handleTooltipToggle('property_acquisition_linkage')}
                            onMouseDown={(e) => {
                              e.preventDefault()
                              handleTooltipToggle('property_acquisition_linkage')
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  marginTop: '0px !important',
                                  maxWidth: '200px'
                                }
                              }
                            }}
                          >
                            <IconButton style={{ width: '24px', height: '24px', padding: '0px', marginRight: '5px' }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <CardText>{`₪${
                          (paymentItem.linkage && parseFloat(paymentItem.linkage).toLocaleString()) || '-'
                        }`}</CardText>
                      </Stack>
                      <CustomDivider />
                      <Stack direction='row' justifyContent='space-between' paddingTop='12px'>
                        <Stack direction='row' alignItems='center'>
                          <CardText>מע״מ</CardText>
                        </Stack>
                        <CardText>{`₪${
                          (paymentItem.vat && parseFloat(paymentItem.vat).toLocaleString()) || '-'
                        }`}</CardText>
                      </Stack>
                      <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' alignItems='center'>
                          <CardText isMediumText={true}>סה״כ</CardText>
                        </Stack>
                        <CardText isMediumText={true} isDebt={paymentItem.date === '01.01.1900'}>{`₪${
                          (paymentItem.totalPayment && parseFloat(paymentItem.totalPayment).toLocaleString()) || '-'
                        }`}</CardText>
                      </Stack>
                    </Stack>
                  </PaymentMoreDetailsCardStaticWrapper>
                </Box>
              ) : (
                <PaymentDetailsCardStaticWrapper>
                  <Stack direction='row' justifyContent={isMobile ? 'space-between' : 'none'} paddingBottom='12px'>
                    <Stack direction='row'>
                      {paymentItem.date === '01.01.1900' ? null : paymentItem.receptionNumber === '-' ? null : (
                        <GreenPaymentConfirmation />
                      )}
                      <Text>{paymentItem.date === '01.01.1900' ? 'יתרת חוב' : paymentItem.date}</Text>
                    </Stack>
                    {isMobile ? (
                      <Box>
                        <DetailButton onClick={() => handleDetailClick(index)}>פירוט</DetailButton>
                      </Box>
                    ) : null}
                  </Stack>
                  {isMobile ? (
                    <Stack direction='row' justifyContent='space-between'>
                      <Box>
                        <AmountTitle>סכום כולל מע״מ</AmountTitle>
                        <PaymentAmountResult coloredText={paymentItem.date === '01.01.1900'}>
                          {parseFloat(paymentItem.totalPayment).toLocaleString()}
                        </PaymentAmountResult>
                      </Box>
                      <Box>
                        <AmountTitle>משולם עבור</AmountTitle>
                        <AmountSubTitle>{paymentItem?.paidFor}</AmountSubTitle>
                      </Box>
                    </Stack>
                  ) : (
                    <>
                      <Box>
                        <AmountTitle>סכום כולל מע״מ</AmountTitle>
                        <PaymentAmountResult coloredText={paymentItem.date === '01.01.1900'}>
                          {parseFloat(paymentItem.totalPayment).toLocaleString()}
                        </PaymentAmountResult>
                      </Box>
                      <Box>
                        <AmountTitle>משולם עבור</AmountTitle>
                        <AmountSubTitle>{paymentItem?.paidFor}</AmountSubTitle>
                      </Box>
                    </>
                  )}
                  {isMobile ? null : <DetailButton onClick={() => handleDetailClick(index)}>פירוט</DetailButton>}
                </PaymentDetailsCardStaticWrapper>
              )}
            </React.Fragment>
          ))}
      </Stack>

      {isMobile
        ? null
        : allPaymentListData.length >= 6 && (
            <>
              <Box position={'absolute'} top='50%' left='119px' sx={{ transform: 'scaleX(1)' }}>
                <IconButton
                  sx={{
                    height: '32px',
                    width: '32px',
                    borderRadius: '50%',
                    background: '#4295E5',
                    '&:hover': { background: '#4295E5' },
                    '&:disabled': {
                      background: '#A0A0A0',
                      cursor: 'not-allowed'
                    },
                    display: visibleIndex === allPaymentListData.length - 6 ? 'none' : 'flex'
                  }}
                  onClick={handleNextClick}
                  disabled={visibleIndex === allPaymentListData.length - 6}
                >
                  <ChevronLeft width='5' height='10' fill='white' />
                </IconButton>
              </Box>
              <Box position={'absolute'} top='50%' right='119px' sx={{ transform: 'scaleX(-1)' }}>
                <IconButton
                  sx={{
                    height: '32px',
                    width: '32px',
                    borderRadius: '50%',
                    background: '#4295E5',
                    '&:hover': { background: '#4295E5' },
                    '&:disabled': {
                      background: '#A0A0A0',
                      cursor: 'not-allowed'
                    },
                    display: visibleIndex === 0 ? 'none' : 'flex'
                  }}
                  onClick={handlePrevClick}
                  disabled={visibleIndex === 0}
                >
                  <ChevronLeft width='5' height='10' fill='white' />
                </IconButton>
              </Box>
            </>
          )}
    </InnerWrapper>
  )
}

export default NextPayment
