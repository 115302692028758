import React from 'react'
import classNames from 'classnames'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'
import { ItemText } from '../../ChatSideBar/ChatSideBar'
import styled from 'styled-components'
import Wallpaper from '../../../../assets/icons/wallpaper.svg'
import { Box } from '@mui/material'
import formatText from './formatTextUtils'
type Props = {
  title?: string | any
  text?: string
  timestamp?: Date | any
  owner?: string
  subject?: string
  Notes?: string
  CntctDate?: string
  item?: string | {}
  fileMessage?: any
  fileResponse?: any
  className?: string
  url?: string
  filename?: string | any
}

function ChatCard({ filename, url, item, Notes, subject, title, text, timestamp, owner, className }: Props) {
  const formattedText = formatText(url)
  const filenameString = filename
  const maxLength = 15
  const truncatedFilename = filename?.length > maxLength ? `${filenameString.substring(0, maxLength)}...` : filename

  return (
    <FormattedTextWrapper className={formattedText ? 'formattedTextWrapper' : ''}>
      <ChatSingle
        className={classNames(
          {
            'user-chat': owner === 'user',
            'chat show-box': owner !== 'user'
          },
          className
        )}
      >
        {isNaN(title) && title && <ItemText>{title}</ItemText>}

        <ChatSingleInnerWrapper className='inner-file'>
          {formattedText && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <InnerWrapper>
                <img style={{ width: 36, height: 28 }} src={Wallpaper} alt='Wallpaper icon' />
              </InnerWrapper>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>{truncatedFilename}</p>
                <div className='formattedText' dangerouslySetInnerHTML={{ __html: formattedText }} />
              </Box>
            </Box>
          )}

          <ItemWrapper>{!formattedText && text}</ItemWrapper>
          {/* <p>{subject}</p> */}
          {/*<ItemText className='timemsg'>
            <>
              {owner ? (
                <>{new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>
              ) : (
                <>{new Date(timestamp).toLocaleDateString()}</>
              )}
            </>
              </ItemText>*/}
        </ChatSingleInnerWrapper>
      </ChatSingle>
    </FormattedTextWrapper>
  )
}

export default ChatCard

const FormattedTextWrapper = styled('div')({
  '&.formattedTextWrapper': {
    '& .user-chat ': {
      width: '30%',
      border: `1px solid ${Colors.lightBlueTwo}`,
      [theme.breakpoints.down('md')]: {
        width: '70%'
      }
    }
  }
})

const ChatSingle = styled('div')({
  minHeight: '62px',
  width: '50%',
  margin: '2rem 3rem',
  padding: '12px 16px 4px',
  borderRadius: '24px',
  background: Colors.lightBlueTwo,
  '&.file-message-wrapper': {
    background: Colors.white,
    border: `1px solid ${Colors.lightBlueTwo}`,
    margin: '10px auto 1rem 5%',
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    '& .inner-file': {
      textAlign: 'center',
      '& div': {
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center'
        }
      },
      '& .timemsg': {
        width: '100%',
        padding: '2px 8px',
        borderRadius: 6
      }
    },
    '& p': {
      paddingBottom: 6,
      marginBlockStart: 0
    }
  },
  '&.user-chat': {
    background: Colors.white,
    margin: '10px auto 1rem 5%',
    '& .formattedText a': {
      '&:hover': {
        color: Colors.activeLink
      }
    }
  },
  '&.classOption': {
    display: 'none'
  },

  [theme.breakpoints.down('md')]: {
    width: '80%',
    margin: '1rem 2rem'
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    margin: '1rem'
  }
})
const ChatSingleInnerWrapper = styled('div')({})

const ItemWrapper = styled('div')({
  fontSize: '16px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: Colors.darkBrown,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&.timemsg': {
    direction: 'ltr',
    padding: '5px 0'
  },
  '& svg': {
    borderRadius: '50%'
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'right'
  }
})

const InnerWrapper = styled('div')({
  width: '100%',
  textAlign: 'center'
})
