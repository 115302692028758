import React, { useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
/*styles*/
import { styled } from '@mui/material/styles'
import { AccordionWrapper, DimanicText, ItemText } from '../../../../constants/styled.components.constants'
import TenantsChangesModal from './TenantsChangesModal'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AccordionWrapperInner, CustomDivider, DataWrapper, DataWrapperInner } from './TenantsChangesContentStyle'

interface ITenantsDataProps {
  Date: string
  ChangeEstimateID: string
  Price: string
  TotalWorkingDays: string
  changesDetails: [
    {
      subject: string
      date: string
      room: string
      quantityChanges: string
      description: string
    }
  ]
}

function AccordionTenantsChangesContent({
  changesDetails,
  Date,
  ChangeEstimateID,
  Price,
  TotalWorkingDays
}: ITenantsDataProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [expanded, setExpanded] = useState<string | false>('')
  const [openProfileModal, setOpenProfileModal] = useState(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <AccordionWrapper onClick={() => setOpenProfileModal(true)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <AccordionWrapperInner>
              <DimanicText> הצעה מס’ {ChangeEstimateID} </DimanicText>
              <DataWrapper>
                <DataWrapperInner>
                  <ItemText>תאריך הצעת מחיר</ItemText>
                  <DimanicText>{Date}</DimanicText>
                </DataWrapperInner>
                <DataWrapperInner>
                  <ItemText>עלות השינוי</ItemText>
                  <DimanicText> ₪{parseFloat(Price).toLocaleString()}</DimanicText>
                </DataWrapperInner>
                <DataWrapperInner>
                  <ItemText>זמן לביצוע</ItemText>
                  <DimanicText> {TotalWorkingDays} ימי עבודה </DimanicText>
                </DataWrapperInner>
              </DataWrapper>
            </AccordionWrapperInner>
          </AccordionSummary>
        </Accordion>
      </AccordionWrapper>

      <TenantsChangesModal
        style={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiCard-root': {
            width: 'fit-content',
            margin: 'auto'
          }
        }}
        open={openProfileModal}
        setOpenModal={setOpenProfileModal}
        modalContent={
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={isMobile ? '326px' : '800px'}
            height={isMobile ? '712px' : '591px'}
            borderRadius={'8px'}
          >
            <Stack direction='column' spacing={0.5} sx={{ direction: 'rtl' }} marginBottom='13px'>
              <Typography fontSize={isMobile ? '22px' : '44px'} fontWeight={600} color={'#473B33'}>
                הצעה מס’ {ChangeEstimateID}
              </Typography>
              <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'}>
                עלות שינוי ₪{parseFloat(Price).toLocaleString()}
              </Typography>
              <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'} paddingBottom='15px'>
                תאריך הצעת מחיר {Date}
              </Typography>
              <CustomDivider />
            </Stack>
            <Box
              overflow={'auto'}
              paddingRight='18px'
              sx={{
                '&::-webkit-scrollbar-track ': {
                  boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                  borderRadius: '10px',
                  backgroundColor: '#EAF2FA'
                },
                '&::-webkit-scrollbar': {
                  width: isMobile ? '4px' : '9px',
                  backgroundColor: '#f5f5f5',
                  margin: '0 8px'
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10px',
                  boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                  backgroundColor: '#0053A3'
                }
              }}
            >
              {changesDetails.map((item, index) => (
                <Stack sx={{ direction: 'rtl' }}>
                  <Typography
                    className='offer-sub-title'
                    fontSize={'22px'}
                    fontWeight={600}
                    color={'#4295E5'}
                    padding={isMobile ? '9px 0 16px 0s' : '11px 0 15px 0'}
                  >
                    {item.room}
                  </Typography>
                  <CustomDivider />
                  <Stack direction='column' padding={'6px 0'} position='relative'>
                    <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'}>
                      {item.subject}
                    </Typography>
                    <Typography fontSize={'14px'} fontWeight={400} color={'#6F655B'}>
                      תאור
                    </Typography>
                    <Typography fontSize={'22px'} width={{ sm: '90%' }} fontWeight={400} color={'#473B33'} lineHeight={'28px'}>
                      {item.description}
                    </Typography>
                    {isMobile ? (
                      <Stack direction='row'>
                        <Stack direction='column' paddingLeft='32px'>
                          <Typography fontSize={'14px'} fontWeight={400} color={'#6F655B'} lineHeight={'28px'}>
                            כמות
                          </Typography>
                          <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'} lineHeight={'28px'}>
                            {item.quantityChanges}
                          </Typography>
                        </Stack>
                        <Stack direction='column'>
                          <Typography fontSize={'14px'} fontWeight={400} color={'#6F655B'} lineHeight={'28px'}>
                            תאריך תוספות
                          </Typography>
                          <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'} lineHeight={'28px'}>
                            {item.date}
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : null}
                    {isMobile ? null : (
                      <Box position='absolute' top={'22px'} left={'30px'} display='flex' textAlign='center'>
                        <Stack direction='column'>
                          <Typography fontSize={'14px'} fontWeight={400} color={'#6F655B'} lineHeight={'28px'}>
                            כמות
                          </Typography>
                          <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'} lineHeight={'28px'}>
                            {item.quantityChanges}
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                  <CustomDivider />
                </Stack>
              ))}
            </Box>
          </Box>
        }
      />
    </>
  )
}

export default AccordionTenantsChangesContent

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))
