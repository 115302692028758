import React from 'react'
import styled from 'styled-components'
import { SectionTitle, UserText } from '../../../constants/styled.components.constants'
import { theme } from '../../../constants/theme.constants'
import { useAppSelector } from '../../../hooks/redux.hooks'
import { useGetTimeMessage } from '../../../hooks/useGetTimeMessage/useGetTimeMessage'
import { IconKey } from '../../../constants/icons.constants'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'

type Props = {
  contractInfoData?: any
}

function TopSection({ contractInfoData }: Props) {
  const { width, breakpoint } = UseResizeWindow()
  const timeStatement = useGetTimeMessage()
  const { user, loading } = useAppSelector((state) => state.user)

  const ShowUser = () =>
    user && !loading ? (
      <UserText>
        {timeStatement} {user?.info?.username}
      </UserText>
    ) : (
      <></>
    )

  return (
    <>
      <ShowUser />
      <SectionTitle> התקדמות הפרוייקט</SectionTitle>
      <FlexAlignItems>
        <KeyWrapper>
          <IconKey />
          תאריך משוער לקבלת מפתח
          <DateKey>{contractInfoData?.purchaseOverview?.estimatedDate}</DateKey>
        </KeyWrapper>
      </FlexAlignItems>
    </>
  )
}

export default TopSection

const FlexAlignItems = styled('div')({
  display: 'flex',
  alignItems: 'center'
})
const KeyWrapper = styled('div')({
  background: '#EEECE7',
  padding: '7px 1rem ',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 20,
  [theme.breakpoints.down('md')]: {
    margin: '1rem 0',
    padding: '5px 8px'
  }
})
const DateKey = styled('p')({
  fontsize: '22px',
  fontWeight: 600,
  lineheight: '32px',
  color: '#00468A  ',
  padding: '0 8px'
})
