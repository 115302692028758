import React from 'react'

const LogoutIcon = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.8333 7.33317V5.49984C12.8333 4.48732 12.0125 3.6665 11 3.6665H4.58333C3.57081 3.6665 2.75 4.48732 2.75 5.49984V16.4998C2.75 17.5124 3.57081 18.3332 4.58333 18.3332H11C12.0125 18.3332 12.8333 17.5124 12.8333 16.4998V14.6665'
      stroke='#473B33'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.41663 10.3125C6.03693 10.3125 5.72913 10.6203 5.72913 11C5.72913 11.3797 6.03693 11.6875 6.41663 11.6875V10.3125ZM19.25 11V11.6875C19.528 11.6875 19.7787 11.52 19.8851 11.2631C19.9915 11.0062 19.9327 10.7105 19.7361 10.5139L19.25 11ZM16.9861 7.76386C16.7176 7.49538 16.2823 7.49538 16.0138 7.76386C15.7453 8.03235 15.7453 8.46765 16.0138 8.73614L16.9861 7.76386ZM16.0139 13.2639C15.7454 13.5323 15.7454 13.9677 16.0139 14.2361C16.2823 14.5046 16.7177 14.5046 16.9861 14.2361L16.0139 13.2639ZM19.7361 11.4861C20.0046 11.2177 20.0046 10.7823 19.7361 10.5139C19.4677 10.2454 19.0323 10.2454 18.7639 10.5139L19.7361 11.4861ZM6.41663 11.6875H19.25V10.3125H6.41663V11.6875ZM19.7361 10.5139L16.9861 7.76386L16.0138 8.73614L18.7638 11.4861L19.7361 10.5139ZM16.9861 14.2361L19.7361 11.4861L18.7639 10.5139L16.0139 13.2639L16.9861 14.2361Z'
      fill='#473B33'
    />
  </svg>
)

export default LogoutIcon
