import React from 'react'
import { ItemList, TextDimriBold } from '../../../constants/styled.components.constants'
import { ItemText } from './ChatSideBar'

type Props = {
  serviceCallID: any
  onNewMessage: any
  selectedSubCategory: {} | any
  date: any
  isSelected?: boolean
  callID?: any
}

function NewChatMessage({ serviceCallID, onNewMessage, date, selectedSubCategory, callID, isSelected }: Props) {
  return (
    <ItemList
      id='NewChatMessage'
      className={`${isSelected || callID === null ? 'firstMsg' : ''}`}
      onClick={onNewMessage}
    >
      <div className='inner'>
        <TextDimriBold>{selectedSubCategory ? selectedSubCategory?.text : ' פנייה חדשה'}</TextDimriBold>
        {serviceCallID && (
          <ItemText className='appeal-num'> {serviceCallID ? `מספר פנייה ${serviceCallID}` : ''}</ItemText>
        )}
        <ItemText className='pad-12'>{serviceCallID ? 'פתוח' : 'טיוטה'}</ItemText>
      </div>
      <ItemText>{date}</ItemText>
    </ItemList>
  )
}

export default NewChatMessage
