import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes.constants'
import { Plus, ButtonText, FlexWrapperTop, TextDimri, Arrow } from '../../../constants/styled.components.constants'
import { Button, styled } from '@mui/material'
/*styles*/
import { Colors } from '../../../constants/styles.constants'
import { theme } from '../../../constants/theme.constants'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import { setCallID } from '../../../state/features/chat.feature'

type Props = {
  newRequestState?: any
  setVisibleForm?: any
  setShowChatList?: any
  showChatList?: any
  toggleChatView?: any
  visibleForm?: boolean
}

function MyAppeals({ visibleForm, setShowChatList, showChatList, setVisibleForm, newRequestState }: Props) {
  const { setMessages, setCallID, callID } = useChatMessages()
  const [clickedOnce, setClickedOnce] = useState(false)

  const navigate = useNavigate()

  const toNewAppealChat = () => {
    if (!clickedOnce) {
      setVisibleForm(false)
      setCallID(null)
      setMessages(newRequestState)
      setClickedOnce(true)
      if (window.innerWidth <= 900) {
        setShowChatList(!showChatList)
      }
    } else {
      window.location.href = '/dashboard/onlinechat'
    }
  }

  return (
    <MainWrapper>
      <InnerFlex>
        <Link to={`${ROUTES.DASHBOARD}#progressStages`}>
          <TextDimri>איזור אישי</TextDimri>
        </Link>

        <Arrow> {'>'} </Arrow>
        <InnerText>הפניות שלי</InnerText>
      </InnerFlex>
      <FlexWrapperTop>
        <Button onClick={toNewAppealChat} variant='outlined'>
          <Plus>+</Plus>
          <ButtonText>פנייה חדשה</ButtonText>
        </Button>
      </FlexWrapperTop>
    </MainWrapper>
  )
}

export default MyAppeals

const MainWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  width: '100%',
  height: '60px',
  [theme.breakpoints.down('md')]: {
    padding: '10px 0',
    height: 'auto'
  }
})

const InnerFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 0 1.5rem 0',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
})

const InnerText = styled('div')({
  fontSize: '18px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'right',
  color: Colors.black
})
