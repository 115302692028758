import React from 'react'

function chatIcon() {
  return (
    <svg width='13' height='11' viewBox='0 0 13 11' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='w8gd3dhbta'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
      </defs>
      <g clipPath='url(#w8gd3dhbta)' transform='translate(-1633 -384)'>
        <path
          d='M1644.526 384.8h-10.146a.929.929 0 0 0-.927.928v7.144c0 .512.416.928.927.928h10.146a.929.929 0 0 0 .927-.928v-7.144a.929.929 0 0 0-.927-.928zm-3.321 4.5 3.832-3.835c.041.079.064.168.064.263v7.144a.573.573 0 0 1-.064.263l-3.832-3.835zm3.584-4.084-4.132 4.134a1.704 1.704 0 0 1-2.408 0l-.577-.577a.176.176 0 1 0-.248.25l.277.277-3.833 3.835a.573.573 0 0 1-.064-.263v-7.144c0-.095.024-.184.064-.263l2.995 2.996a.175.175 0 0 0 .248 0 .176.176 0 0 0 0-.249l-2.994-2.996a.572.572 0 0 1 .263-.064h10.146c.094 0 .184.023.263.064zm-10.409 8.232a.572.572 0 0 1-.263-.064l3.833-3.835.05.05c.4.4.927.601 1.453.601s1.052-.2 1.453-.601l.05-.05 3.833 3.835a.572.572 0 0 1-.263.064h-10.146z'
          stroke='#242424'
          strokeWidth='.5'
          fill='none'
          strokeMiterlimit='10'
        />
      </g>
    </svg>
  )
}

export default chatIcon
