import React from 'react'

function HouseIcons() {
  return (
    <svg width='25' height='26' viewBox='0 0 25 26' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='ykiixzyvqa'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='dc6y4aa7ub'>
          <path d='M16.667 0v6.234l1.876 1.802.207.198v14l1.128 1.083a.388.388 0 0 1 0 .566.425.425 0 0 1-.295.117.425.425 0 0 1-.294-.117L18.16 22.8H2.083v-2H0V0h16.667zm-5 2.8h-8.75V22h14.41l-3.13-3.006a5.027 5.027 0 0 1-3.175 1.118c-2.717 0-4.928-2.122-4.928-4.73 0-2.61 2.21-4.732 4.928-4.732s4.928 2.122 4.928 4.731c0 1.161-.44 2.224-1.164 3.048l3.13 3.005V8.8h-6.25v-6zm4.166-2h-15V20h1.25V2h10.173l.207.199 3.37 3.235V.8zm-4.81 10.65c-2.259 0-4.096 1.764-4.096 3.931 0 2.168 1.837 3.931 4.095 3.931s4.095-1.763 4.095-3.93c0-2.168-1.837-3.932-4.095-3.932zm2.57 4.55c.231 0 .417.179.417.4 0 .221-.186.4-.416.4h-5a.408.408 0 0 1-.417-.4c0-.221.186-.4.417-.4h5zm-2.5-2.4c.231 0 .417.179.417.4 0 .221-.186.4-.416.4h-2.5a.408.408 0 0 1-.417-.4c0-.221.186-.4.417-.4h2.5zM12.5 3.366V8h4.828l-.661-.634-4.167-4z' />
        </clipPath>
        <clipPath id='fdwgepkxvc'>
          <path d='M21.05-1.05v26.1h-22.1v-26.1h22.1zM16.667 0H0v20.8h2.083v2h16.078l1.128 1.083a.425.425 0 0 0 .294.117c.107 0 .214-.04.295-.117a.388.388 0 0 0 0-.566l-1.128-1.083v-14l-.207-.198-1.876-1.802V0zm-5 2.8v6h6.25v12.634l-3.131-3.005a4.598 4.598 0 0 0 1.164-3.048c0-2.609-2.21-4.731-4.928-4.731-2.717 0-4.928 2.122-4.928 4.731s2.21 4.731 4.928 4.731c1.21 0 2.316-.422 3.175-1.118L17.327 22H2.918V2.8h8.75zm4.166-2v4.634L12.463 2.2 12.256 2H2.083v18H.833V.8h15zm-4.81 10.65c2.257 0 4.094 1.764 4.094 3.931 0 2.168-1.837 3.931-4.095 3.931s-4.095-1.763-4.095-3.93c0-2.168 1.837-3.932 4.095-3.932zm2.57 4.55h-5a.408.408 0 0 0-.416.4c0 .221.186.4.417.4h5c.23 0 .416-.179.416-.4 0-.221-.186-.4-.416-.4zm-2.5-2.4h-2.5a.408.408 0 0 0-.416.4c0 .221.186.4.417.4h2.5c.23 0 .416-.179.416-.4 0-.221-.186-.4-.416-.4zM12.5 3.366l4.167 4 .66.634H12.5V3.366z' />
        </clipPath>
      </defs>
      <g clipPath='url(#ykiixzyvqa)' transform='translate(-1393 -1989)'>
        <g clipPath='url(#dc6y4aa7ub)' transform='translate(1395.5 1990)'>
          <path fill='#443A2C' d='M0 0h20v24H0V0z' />
        </g>
        <g clipPath='url(#fdwgepkxvc)' transform='translate(1395.5 1990)'>
          <path
            d='M16.667 0v6.234l1.876 1.802.207.198v14l1.128 1.083a.388.388 0 0 1 0 .566.425.425 0 0 1-.295.117.425.425 0 0 1-.294-.117L18.16 22.8H2.083v-2H0V0h16.667zm-5 2.8h-8.75V22h14.41l-3.13-3.006a5.027 5.027 0 0 1-3.175 1.118c-2.717 0-4.928-2.122-4.928-4.73 0-2.61 2.21-4.732 4.928-4.732s4.928 2.122 4.928 4.731c0 1.161-.44 2.224-1.164 3.048l3.13 3.005V8.8h-6.25v-6zm4.166-2h-15V20h1.25V2h10.173l.207.199 3.37 3.235V.8zm-4.81 10.65c-2.259 0-4.096 1.764-4.096 3.931 0 2.168 1.837 3.931 4.095 3.931s4.095-1.763 4.095-3.93c0-2.168-1.837-3.932-4.095-3.932zm2.57 4.55c.231 0 .417.179.417.4 0 .221-.186.4-.416.4h-5a.408.408 0 0 1-.417-.4c0-.221.186-.4.417-.4zm-2.5-2.4c.231 0 .417.179.417.4 0 .221-.186.4-.416.4h-2.5a.408.408 0 0 1-.417-.4c0-.221.186-.4.417-.4zM12.5 3.366V8h4.828l-.661-.634-4.167-4z'
            stroke='#443A2C'
            strokeWidth='.1'
            fill='none'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  )
}

export default HouseIcons
