import { Box, styled } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'

export const InnerWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '0px 175px',
  alignItems: 'center',
  maxWidth: '1366px',
  margin: 'auto',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: '24px 0 0 0'
  }
})

export const PaymentDetailsCardStaticWrapper = styled(Box)({
  height: '234px',
  width: '152px',
  background: Colors.white,
  padding: '1.3rem 1rem',
  marginLeft: '0.75rem',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    height: '130px',
    width: '326px',
    padding: '1.25rem 1rem',
    marginBottom: '0.688rem'
  }
})

export const PaymentMoreDetailsCardStaticWrapper = styled('div')({
  height: '272px',
  width: '270px',
  background: Colors.white,
  padding: '2rem 1rem',
  marginLeft: '0.75rem',
  boxShadow: '0px 8px 16px 0px rgba(198, 215, 226, 0.32)',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '326px',
    marginBottom: '0.75rem'
  }
})

export const PaymentAmountResult = styled('b', {
  shouldForwardProp: (props: string) => !['coloredText'].includes(props)
})<{
  coloredText?: boolean
}>(({ coloredText, theme }) => ({
  fontSize: '30px',
  fontWeight: '400',
  color: coloredText ? '#FF4C00' : Colors.black,
  padding: 0
}))

export const AmountTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  color: '#6F655B'
})

export const AmountSubTitle = styled('div')({
  fontSize: '22px',
  fontWeight: 400,
  color: '#6F655B'
})

export const SubTitle = styled('div')({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: 400,
  color: Colors.darkBrown
})

export const CardText = styled('p', {
  shouldForwardProp: (props: string) => !['isMediumText', 'isDebt'].includes(props)
})<{
  isMediumText?: boolean
  isDebt?: boolean
}>(({ isMediumText, isDebt, theme }) => ({
  fontSize: '25px',
  fontWeight: isMediumText ? 600 : 400,
  color: isDebt ? '#FF4C00' : Colors.darkBrown,
  padding: ' 0 0 4px 0',
  '&.total-upcomingPayments': {
    color: Colors.oragne
  },
  '&.bold-section': {
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('md')]: {}
}))

export const CustomDivider = styled(Box)({
  border: `1px solid #EEECE7`,
  width: '100%'
})

export const DetailButton = styled('p')({
  fontSize: '18px',
  color: Colors.blue,
  borderBottom: `1px solid ${Colors.blue}`,
  width: '30px',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
})
