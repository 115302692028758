import React from 'react'

function dryFlooringIcon() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='iasix1nksa'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='mv00ia7eqb'>
          <path d='M408 20c13.807 0 25 11.193 25 25v542c0 13.807-11.193 25-25 25H25c-13.807 0-25-11.193-25-25V45c0-13.807 11.193-25 25-25h383z' />
        </clipPath>
        <clipPath id='b87xqwvvsc'>
          <path d='M28 0a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24z' />
        </clipPath>
        <clipPath id='er2s710gdd'>
          <path d='M17.68 0a.32.32 0 0 1 .32.32v17.36a.32.32 0 0 1-.32.32H.32a.32.32 0 0 1-.32-.32V.32A.32.32 0 0 1 .32 0h17.36zm-.32 13.458-3.62 3.62-.282.282h3.902v-3.902zm-6.338 1.356L8.476 17.36h4.077l.507-.507-2.038-2.04zm.24-4.892L3.824 17.36h3.747l5.564-5.565-1.873-1.873zm-8.554 3.715L.64 15.705v1.655H2.92l1.755-1.756-1.966-1.967zM17.36 8.476l-3.546 3.546-2.34 2.34 2.039 2.038 3.847-3.847V8.476zm-4.383-5.108L3.16 13.185l1.967 1.967 9.817-9.817-1.967-1.967zM6.343 4.886.64 10.5v4.3l1.838-1.838 5.97-5.97-2.105-2.106zM17.36 3.824l-5.646 5.645 1.874 1.874L17.35 7.58a.617.617 0 0 1 .01-.01V3.825zM1.003 4.934l-.363.342V9.6l2.535-2.495-2.172-2.172zM9.723.661H5.539L1.47 4.495l2.162 2.162L9.723.661zm5.076-.02h-4.142L6.799 4.436l2.102 2.102L14.799.64zm2.56 0h-1.654l-2.276 2.275 1.967 1.966L17.36 2.92V.64zM4.63.64H.64v3.756l.12-.113a.339.339 0 0 1 .06-.057L4.628.64z' />
        </clipPath>
        <clipPath id='5d0wy9av2e'>
          <path d='M19.2-1.2v20.4H-1.2V-1.2h20.4zM17.68 0H.32A.32.32 0 0 0 0 .32v17.36c0 .177.143.32.32.32h17.36a.32.32 0 0 0 .32-.32V.32a.32.32 0 0 0-.32-.32zm-.32 13.458v3.902h-3.902l3.902-3.902zm-6.338 1.356 2.038 2.039-.507.507H8.476l2.546-2.546zm.24-4.892 1.873 1.873-5.564 5.565H3.824l7.438-7.438zm-8.554 3.715 1.966 1.967L2.92 17.36H.64v-1.655l2.068-2.068zM17.36 8.476v4.077L13.513 16.4l-2.039-2.039 2.34-2.34 3.546-3.545zm-4.383-5.108 1.967 1.967-9.817 9.817-1.967-1.967 9.817-9.817zM6.343 4.886l2.105 2.105-5.97 5.97L.64 14.8v-4.3l5.703-5.613zM17.36 3.824v3.747a.617.617 0 0 0-.01.009l-3.762 3.763-1.874-1.874 5.646-5.645zM1.003 4.934l2.172 2.172L.64 9.601V5.276l.363-.342zM9.723.661 3.63 6.657 1.47 4.495 5.539.661h4.184zm5.076-.02L8.901 6.538 6.799 4.437 10.657.64h4.142zm2.56 0v2.278l-1.963 1.963-1.967-1.966L15.705.64h1.655zM4.63.64.82 4.226a.318.318 0 0 0-.06.057l-.12.113V.64h3.988z' />
        </clipPath>
      </defs>
      <g clipPath='url(#iasix1nksa)' transform='translate(-1385 -1357)'>
        <g clipPath='url(#mv00ia7eqb)' transform='translate(1004 1281)'>
          <g clipPath='url(#b87xqwvvsc)' transform='translate(381 76)'>
            <path fill='#F79780' d='M0 0h32v32H0V0z' />
          </g>
          <g clipPath='url(#er2s710gdd)' transform='translate(388 83)'>
            <path fill='#FFF' d='M0 0h18v18H0V0z' />
          </g>
          <g clipPath='url(#5d0wy9av2e)' transform='translate(388 83)'>
            <path
              d='M17.68 18H.32a.32.32 0 0 1-.32-.32V.32A.32.32 0 0 1 .32 0h17.36a.32.32 0 0 1 .32.32v17.36a.32.32 0 0 1-.32.32zm-4.222-.64h3.902v-3.902l-3.62 3.62-.002.003-.28.279zm-4.982 0h4.077l.507-.507-2.038-2.04-2.546 2.547zm-4.652 0h3.747l5.564-5.565-1.873-1.873-5.909 5.909-1.529 1.529zm-3.184 0H2.92l1.755-1.756-1.966-1.967L.64 15.705v1.655zm10.834-2.999 2.039 2.039 3.847-3.847V8.476l-3.546 3.546-2.34 2.34zM3.16 13.185l1.967 1.967 9.817-9.817-1.967-1.967-3.849 3.849-5.968 5.968zM.64 10.5v4.3l1.838-1.838.003-.004.003-.003 5.964-5.964-2.105-2.105L.64 10.5zm11.074-1.03 1.874 1.873L17.35 7.58a.617.617 0 0 1 .01-.01V3.825l-5.646 5.645zM.64 5.275V9.6l2.535-2.495-2.172-2.172-.363.342zm.829-.78 2.162 2.161L9.723.661H5.539L1.47 4.495zm5.33-.059 2.102 2.102L14.799.64h-4.142L6.799 4.437zm6.63-1.521 1.967 1.966L17.36 2.92V.64h-1.655l-2.276 2.276zM.64.64v3.757l.12-.113a.339.339 0 0 1 .06-.057L4.628.64H.64z'
              stroke='#FFF'
              strokeWidth='.4'
              fill='none'
              strokeMiterlimit='5'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default dryFlooringIcon
