import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { StyledCard, StyledCardContent, StyledModal } from './TenantsChangesModalStyled'

interface ITenantsChangesModalProps {
  isAbsolute?: boolean
  right?: string | number
  left?: string | number
  top?: string | number
  bottom?: string | number
  showBackDrop?: boolean
  width?: string | number
  cardContentPadding?: string | number
  modalContent: React.ReactNode
  open: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  setOnEdit?: Dispatch<SetStateAction<boolean>>
  style?: any
}

const TenantsChangesModal: React.FC<ITenantsChangesModalProps> = (props: ITenantsChangesModalProps) => {
  const {
    open,
    setOpenModal,
    setOnEdit,
    modalContent,
    isAbsolute,
    right,
    left,
    top,
    bottom,
    showBackDrop,
    width,
    cardContentPadding,
    style
  } = props
  return (
    <div>
      <StyledModal
        sx={style || {}}
        isAbsolute={isAbsolute}
        right={right}
        top={top}
        left={left}
        bottom={bottom}
        width={width}
        showBackDrop={showBackDrop}
        open={open}
        onClose={() => {
          setOpenModal(false)
          setOnEdit?.(false)
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <StyledCard>
          <CloseIcon
            sx={{
              height: '24px',
              width: '24px',
              color: '#473B33',
              cursor: 'pointer',
              position: 'absolute',
              top: '24px',
              left: '24px'
            }}
            onClick={() => setOpenModal(false)}
          />
          <StyledCardContent padding={cardContentPadding}>{modalContent}</StyledCardContent>
        </StyledCard>
      </StyledModal>
    </div>
  )
}

export default TenantsChangesModal
