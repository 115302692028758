import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../redux.hooks'
import { useChatMessages } from '../useAddMessage/useChatMessages'
import { API_ENDPOINTS } from '../../constants/api.constant'
import Wallpaper from '../../assets/icons/wallpaper.svg'
import { ChatListInner } from '../../constants/styled.components.constants'
import { Box, Typography } from '@mui/material'
import { Attach } from '../../constants/icons.constants'
import API from '../../services/ajax.service'
import { addNewActiveCall } from '../../state/features/chat.feature'

export const useChatHttp = () => {
  const dispatch = useAppDispatch()

  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [fileColor, setFileColor] = useState<string>('')
  const token = useAppSelector((state) => state.user.user?.info.token)
  const { allQuestionsData } = useAppSelector((state) => state.chat) || []

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>()
  const [formData, setFormData] = useState<{ text?: string; file?: File }>({})

  const { contractId, projectId } = useAppSelector((state: { user: any }) => state.user)
  const { messages, addMessage, updateMessageText, setMessages, setCallID, callID, statusID, setStatusID } =
    useChatMessages()

  const [fileResponse, setFileResponse] = useState<any>(null)

  const handleMessageCalledActivity = async (note: string, files: File[] = []) => {
    try {
      const messageData = {
        subDescription: selectedSubCategory?.text || '',
        note: note,
        projectId: projectId,
        contractId: contractId,
        serviceCallID: callID
      }

      // const uploadedFileName = file ? file.name : ''
      // const fileUploadFormData = new FormData()

      files.forEach((file) => {
        addMessage({
          className: 'file-message-wrapper',
          text: (
            <ChatListInner className='fileMessage'>
              <Box>
                <Box component='img' src={Wallpaper} alt='icon' />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography variant='outlined' style={{ padding: '0 8px' }}>
                    {file.name}
                  </Typography>
                  <Attach />
                </Box>
              </Box>
            </ChatListInner>
          ),
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      })

      setTimeout(() => {
        addMessage({
          title: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      }, 2500)

      const [activityResponse, fileResponse] = await Promise.allSettled([
        note ? API.getInstance().post(API_ENDPOINTS.SEND_ACTIVITY, messageData) : null,
        Promise.all(
          files.map((file) => {
            const fileUploadFormData = new FormData()
            fileUploadFormData.append('file', file)

            return API.getInstance().post(API_ENDPOINTS.UPLOAD_FILES, fileUploadFormData, {
              contractId: contractId,
              serviceCallID: callID,
              fileName: file.name,
              sugDescription: selectedSubCategory?.text || ' '
            })
          })
        )
      ])
      setFileResponse(fileResponse?.status === 'fulfilled' ? fileResponse.value : null)
      console.log('usehook', fileResponse.value)
      console.log('activityResponse', activityResponse)

      const newActivityCall = {
        title: '',
        text: messageData.note,
        filename: null,
        isFile: 0,
        url: null,
        owner: 'user',
        timestamp: new Date().toISOString()
      }
      dispatch(addNewActiveCall({ callID, newActivityCall }))
      console.log('usehookallQuestionsData', allQuestionsData)

      if (activityResponse.status === 'fulfilled' || fileResponse?.status === 'fulfilled') {
        addMessage({
          title: `בהמשך לפנייה ${callID} הודעתך נשלחה בהצלחה`,
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      } else {
        console.log('Activity request failed:', activityResponse.reason)
        setTimeout(() => {
          addMessage({
            title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר',
            owner: 'chat',
            timestamp: Date.now(),
            subject: ''
          })
        }, 3000)
      }

      if (fileResponse?.status === 'fulfilled') {
        // const uploadedFileName = file ? file.name : ''

        setMessages((messages) => {
          const lastFileIndex = messages?.findLastIndex(({ className }: any) => className === 'file-message-wrapper')

          return messages.map((msg: any, msgIndex: number) => {
            if (lastFileIndex !== msgIndex) {
              return msg
            }

            console.log('fileResponse', fileResponse.value)

            const file = fileResponse.value[msgIndex]
            const path = file?.data?.path
            console.log('msg', msg)
            return {
              ...msg,

              text: (
                <ChatListInner key={msgIndex}>
                  <Box>
                    <Box component='img' src={Wallpaper} alt='icon' />

                    {fileResponse?.value.map((item, index) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between !important'
                        }}
                      >
                        <Typography variant='h6' style={{ padding: '0 8px' }}>
                          {item?.config?.params?.fileName}
                        </Typography>
                        <a key={index} className='file-link' href={item.data.path} target='_blank'>
                          להורדת קובץ
                        </a>
                      </Box>
                    ))}
                  </Box>
                </ChatListInner>
              )
            }
          })
        })
      }
      if (selectedFile) {
        setSelectedFile(null)
        setFileColor('')
      }
      setFormData({})
    } catch (err) {
      console.log(err)
    }
  }

  return {
    fileResponse,
    handleMessageCalledActivity,
    selectedFile,
    setSelectedFile,
    fileColor,
    setFileColor
  }
}

// const handleMessageCalledActivity = async (note: string, files: File[] = []) => {
//   try {
//     const messageData = {
//       subDescription: selectedSubCategory?.text || '',
//       note: note,
//       projectId: projectId,
//       contractId: contractId,
//       serviceCallID: callID
//     }

//     console.log('messageData', messageData)

//     // const uploadedFileName = file ? file.name : ''
//     // const fileUploadFormData = new FormData()

//     files.forEach((file) => {
//       addMessage({
//         className: 'file-message-wrapper',
//         text: (
//           <ChatListInner className='fileMessage'>
//             <Box>
//               <Box component='img' src={Wallpaper} alt='icon' />
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center'
//                 }}
//               >
//                 <Typography variant='outlined' style={{ padding: '0 8px' }}>
//                   {file.name}
//                 </Typography>
//                 <Attach />
//               </Box>
//             </Box>
//           </ChatListInner>
//         ),
//         owner: 'chat',
//         timestamp: Date.now(),
//         subject: ''
//       })
//     })

//     setTimeout(() => {
//       addMessage({
//         title: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
//         owner: 'chat',
//         timestamp: Date.now(),
//         subject: ''
//       })
//     }, 2500)

//     const [activityResponse, fileResponse] = await Promise.all([
//       note ? API.getInstance().post(API_ENDPOINTS.SEND_ACTIVITY, messageData) : null,
//       Promise.all(
//         files.map((file) => {
//           const fileUploadFormData = new FormData()
//           fileUploadFormData.append('file', file)

//           return API.getInstance().post(API_ENDPOINTS.UPLOAD_FILES, fileUploadFormData, {
//             contractId: contractId,
//             serviceCallID: callID,
//             fileName: file.name,
//             sugDescription: selectedSubCategory?.text || ' '
//           })
//         })
//       )
//     ])
//     console.log('messageData 2', messageData)
//     console.log('activityResponse.status @@@', activityResponse?.data?.data.ActivityCode)
//     console.log('fileResponse', fileResponse)

//     if (activityResponse?.status === 'fulfilled' || fileResponse?.status === 'fulfilled') {
//       addMessage({
//         title: `בהמשך לפנייה ${activityResponse?.data?.data.ActivityCode} הודעתך נשלחה בהצלחה`,
//         owner: 'chat',
//         timestamp: Date.now(),
//         subject: ''
//       })
//     } else {
//       console.log('Activity request failed:', activityResponse)
//       setTimeout(() => {
//         addMessage({
//           title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר',
//           owner: 'chat',
//           timestamp: Date.now(),
//           subject: ''
//         })
//       }, 3000)
//     }

//     if (fileResponse?.status === 'fulfilled') {
//       // const uploadedFileName = file ? file.name : ''

//       setMessages((messages) => {
//         const lastFileIndex = messages?.findLastIndex(({ className }: any) => className === 'file-message-wrapper')

//         console.log('lastFileIndex', lastFileIndex)
//         return messages.map((msg: any, msgIndex: number) => {
//           if (lastFileIndex !== msgIndex) {
//             return msg
//           }

//           console.log('fileResponse', fileResponse.value)

//           const file = fileResponse.value[msgIndex]
//           const path = file?.data?.path
//           console.log('path@@', path)
//           console.log('file@@', file)
//           console.log('msg', msg)
//           return {
//             ...msg,

//             text: (
//               <ChatListInner key={msgIndex}>
//                 <Box>
//                   <Box component='img' src={Wallpaper} alt='icon' />

//                   {fileResponse?.value.map((item, index) => (
//                     <Box
//                       sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'space-between !important'
//                       }}
//                     >
//                       <Typography variant='h6' style={{ padding: '0 8px' }}>
//                         {item?.config?.params?.fileName}
//                       </Typography>
//                       <a key={index} className='file-link' href={item.data.path} target='_blank'>
//                         להורדת קובץ
//                       </a>
//                     </Box>
//                   ))}
//                 </Box>
//               </ChatListInner>
//             )
//           }
//         })
//       })
//     }
//     if (selectedFile) {
//       setSelectedFile(null)
//       setFileColor('')
//     }
//     setFormData({})
//   } catch (err) {
//     console.log(err)
//   }
// }

// const handleMessageCalledActivity = async (note: string, file: File | null) => {
//   try {
//     const messageData = {
//       subDescription: selectedSubCategory?.text || '',
//       note: note,
//       projectId: projectId,
//       contractId: contractId,
//       serviceCallID: callID
//     }

//     const uploadedFileName = file ? file.name : ''
//     const fileUploadFormData = new FormData()

//     if (file && selectedFile && File) {
//       fileUploadFormData.append('file', file)
//       addMessage({
//         className: 'file-message-wrapper',
//         text: (
//           <ChatListInner className='fileMessage'>
//             <Box>
//               <Box component='img' src={Wallpaper} alt='icon' />
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center'
//                 }}
//               >
//                 <Typography variant='h5' style={{ padding: '0 8px' }}>
//                   {uploadedFileName}
//                 </Typography>
//                 <Attach />
//               </Box>
//             </Box>
//           </ChatListInner>
//         ),
//         owner: 'chat',
//         timestamp: Date.now(),
//         subject: ''
//       })
//     }

//     setTimeout(() => {
//       addMessage({
//         title: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
//         owner: 'chat',
//         timestamp: Date.now(),
//         subject: ''
//       })
//     }, 2500)

//     const [activityResponse, fileResponse] = await Promise.all([
//       note ? API.getInstance().post(API_ENDPOINTS.SEND_ACTIVITY, messageData) : null,
//       file
//         ? API.getInstance().post(API_ENDPOINTS.UPLOAD_FILES, fileUploadFormData, {
//             contractId: contractId,
//             serviceCallID: callID,
//             fileName: file.name,
//             sugDescription: selectedSubCategory?.text || ' '
//           })
//         : null
//     ])
//     console.log('send from use')

//     if (activityResponse?.status === 'fulfilled' || fileResponse?.status === 'fulfilled') {
//       addMessage({
//         title: `בהמשך לפנייה ${callID} הודעתך נשלחה בהצלחה`,
//         owner: 'chat',
//         timestamp: Date.now(),
//         subject: ''
//       })
//       console.log('send from use -2 ')

//       setCallID(callID)
//     } else {
//       console.log('Activity request failed:', activityResponse?.reason)
//       console.log('falied')
//       setTimeout(() => {
//         addMessage({
//           title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר-1',
//           owner: 'chat',
//           timestamp: Date.now(),
//           subject: ''
//         })
//       }, 3000)
//     }

//     if (fileResponse?.status === 'fulfilled') {
//       console.log('send from use - 3')

//       const uploadedFileName = file ? file.name : ''

//       setMessages((messages) => {
//         console.log('send from use -4')

//         const lastFileIndex = messages.findLastIndex(({ className }: any) => className === 'file-message-wrapper')
//         const path = fileResponse?.value?.data?.path

//         return messages.map((msg, msgIndex) => {
//           if (lastFileIndex !== msgIndex) {
//             return msg
//           }

//           return {
//             ...msg,
//             text: (
//               <ChatListInner>
//                 <Box>
//                   <Box component='img' src={Wallpaper} alt='icon' />
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       justifyContent: 'space-between !important'
//                     }}
//                   >
//                     <Typography variant='h5' style={{ padding: '0 8px' }}>
//                       {uploadedFileName}
//                     </Typography>
//                     <a className='file-link' href={path} target='_blank'>
//                       להורדת קובץ
//                     </a>
//                   </Box>
//                 </Box>
//               </ChatListInner>
//             )
//           }
//         })
//       })
//     }
//     if (selectedFile) {
//       setSelectedFile(null)
//       setFileColor('')
//     }
//     setFormData({})
//   } catch (err) {
//     addMessage({
//       title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר-2',
//       owner: 'chat',
//       timestamp: Date.now(),
//       subject: ''
//     })

//     console.log(err)
//   }
// }
