import React from 'react'

const paymentsCalculator = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <clipPath id='g0qhhti0ka'>
        <path d='M1680 0v2959H0V0h1680z' />
      </clipPath>
      <clipPath id='wc6ae8silb'>
        <path d='M13.285 0c.612 0 1.109.497 1.11 1.11v16.113c-.001.613-.498 1.11-1.11 1.11H1.11c-.613 0-1.11-.497-1.11-1.11V1.11C0 .497.497 0 1.11 0h12.175zm0 .501H1.11a.61.61 0 0 0-.609.609v16.113a.61.61 0 0 0 .609.609h12.175a.61.61 0 0 0 .608-.609V1.11a.61.61 0 0 0-.608-.609zM8.558 13.968a.25.25 0 0 1 .25.25v2.432a.25.25 0 0 1-.25.251H5.837a.25.25 0 0 1-.251-.251v-2.431a.25.25 0 0 1 .25-.25h2.722zm-4.15 0a.25.25 0 0 1 .251.25v2.432a.25.25 0 0 1-.25.251H1.682a.25.25 0 0 1-.25-.251v-2.431a.25.25 0 0 1 .25-.25h2.725zm8.304-3.975a.25.25 0 0 1 .25.25v6.407a.25.25 0 0 1-.25.25H9.986a.25.25 0 0 1-.25-.25v-6.407a.25.25 0 0 1 .25-.25h2.726zm-4.405 4.476h-2.22v1.93h2.22v-1.93zm-4.15 0H1.935v1.93h2.224v-1.93zm8.304-3.975h-2.224v5.905h2.224v-5.905zm-3.903-.501a.25.25 0 0 1 .25.25v2.431a.25.25 0 0 1-.25.25H5.837a.25.25 0 0 1-.251-.25v-2.43a.25.25 0 0 1 .25-.251h2.722zm-4.15 0a.25.25 0 0 1 .251.25v2.431a.25.25 0 0 1-.25.25H1.682a.25.25 0 0 1-.25-.25v-2.43a.25.25 0 0 1 .25-.251h2.725zm3.9.5h-2.22v1.93h2.22v-1.93zm-4.15 0H1.934v1.93h2.224v-1.93zm4.4-4.476a.25.25 0 0 1 .25.25v2.431a.25.25 0 0 1-.25.251H5.837a.25.25 0 0 1-.251-.25V6.266a.25.25 0 0 1 .25-.25h2.722zm-4.15 0a.25.25 0 0 1 .251.25v2.431a.25.25 0 0 1-.25.251H1.682a.25.25 0 0 1-.25-.25V6.266a.25.25 0 0 1 .25-.25h2.725zm8.304 0a.25.25 0 0 1 .25.25v2.431a.25.25 0 0 1-.25.251H9.986a.25.25 0 0 1-.25-.25V6.266a.25.25 0 0 1 .25-.25h2.726zm-4.405.501h-2.22v1.93h2.22v-1.93zm-4.15 0H1.935v1.93h2.224v-1.93zm8.304 0h-2.224v1.93h2.224v-1.93zm.25-5.086a.25.25 0 0 1 .251.25v3.152a.25.25 0 0 1-.25.25H1.682a.25.25 0 0 1-.25-.25V1.683a.25.25 0 0 1 .25-.25h11.03zm-.25.502H1.934v2.65H12.46v-2.65z' />
      </clipPath>
      <clipPath id='m08a0nt8jc'>
        <path d='M15.445-1.05v20.433H-1.05V-1.05h16.495zM13.285 0H1.11C.497 0 0 .497 0 1.11v16.113c0 .613.497 1.11 1.11 1.11h12.175c.612 0 1.109-.497 1.11-1.11V1.11C14.394.497 13.897 0 13.285 0zm0 .501a.61.61 0 0 1 .608.609v16.113a.61.61 0 0 1-.608.609H1.11a.61.61 0 0 1-.609-.609V1.11A.61.61 0 0 1 1.11.5zM8.558 13.968H5.837a.25.25 0 0 0-.251.25v2.432a.25.25 0 0 0 .25.251h2.722a.25.25 0 0 0 .25-.25v-2.432a.25.25 0 0 0-.25-.25zm-4.15 0H1.683a.25.25 0 0 0-.25.25v2.432a.25.25 0 0 0 .25.251h2.725a.25.25 0 0 0 .251-.25v-2.432a.25.25 0 0 0-.25-.25zm8.304-3.975H9.986a.25.25 0 0 0-.25.25v6.407a.25.25 0 0 0 .25.25h2.726a.25.25 0 0 0 .25-.25v-6.407a.25.25 0 0 0-.25-.25zm-4.405 4.476v1.93h-2.22v-1.93h2.22zm-4.15 0v1.93H1.935v-1.93h2.224zm8.304-3.975v5.905h-2.224v-5.905h2.224zm-3.903-.501H5.837a.25.25 0 0 0-.251.25v2.431a.25.25 0 0 0 .25.25h2.722a.25.25 0 0 0 .25-.25v-2.43a.25.25 0 0 0-.25-.251zm-4.15 0H1.683a.25.25 0 0 0-.25.25v2.431a.25.25 0 0 0 .25.25h2.725a.25.25 0 0 0 .251-.25v-2.43a.25.25 0 0 0-.25-.251zm3.9.5v1.93h-2.22v-1.93h2.22zm-4.15 0v1.93H1.934v-1.93h2.224zm4.4-4.476H5.837a.25.25 0 0 0-.251.25v2.431a.25.25 0 0 0 .25.251h2.722a.25.25 0 0 0 .25-.25V6.266a.25.25 0 0 0-.25-.25zm-4.15 0H1.683a.25.25 0 0 0-.25.25v2.431a.25.25 0 0 0 .25.251h2.725a.25.25 0 0 0 .251-.25V6.266a.25.25 0 0 0-.25-.25zm8.304 0H9.986a.25.25 0 0 0-.25.25v2.431a.25.25 0 0 0 .25.251h2.726a.25.25 0 0 0 .25-.25V6.266a.25.25 0 0 0-.25-.25zm-4.405.501v1.93h-2.22v-1.93h2.22zm-4.15 0v1.93H1.935v-1.93h2.224zm8.304 0v1.93h-2.224v-1.93h2.224zm.25-5.086H1.684a.25.25 0 0 0-.25.25v3.152a.25.25 0 0 0 .25.25h11.029a.25.25 0 0 0 .25-.25V1.683a.25.25 0 0 0-.25-.25zm-.25.502v2.65H1.934v-2.65H12.46z' />
      </clipPath>
    </defs>
    <g clipPath='url(#g0qhhti0ka)' transform='translate(-207 -1173)'>
      <g clipPath='url(#wc6ae8silb)' transform='translate(209.5 1173.833)'>
        <path fill='#443A2C' d='M0 0h14.395v18.333H0V0z' />
      </g>
      <g clipPath='url(#m08a0nt8jc)' transform='translate(209.5 1173.833)'>
        <path
          d='M13.285 0c.612 0 1.109.497 1.11 1.11v16.113c-.001.613-.498 1.11-1.11 1.11H1.11c-.613 0-1.11-.497-1.11-1.11V1.11C0 .497.497 0 1.11 0zm0 .501H1.11a.61.61 0 0 0-.609.609v16.113a.61.61 0 0 0 .609.609h12.175a.61.61 0 0 0 .608-.609V1.11a.61.61 0 0 0-.608-.609zM8.558 13.968a.25.25 0 0 1 .25.25v2.432a.25.25 0 0 1-.25.251H5.837a.25.25 0 0 1-.251-.251v-2.431a.25.25 0 0 1 .25-.25zm-4.15 0a.25.25 0 0 1 .251.25v2.432a.25.25 0 0 1-.25.251H1.682a.25.25 0 0 1-.25-.251v-2.431a.25.25 0 0 1 .25-.25zm8.304-3.975a.25.25 0 0 1 .25.25v6.407a.25.25 0 0 1-.25.25H9.986a.25.25 0 0 1-.25-.25v-6.407a.25.25 0 0 1 .25-.25zm-4.405 4.476h-2.22v1.93h2.22v-1.93zm-4.15 0H1.935v1.93h2.224v-1.93zm8.304-3.975h-2.224v5.905h2.224v-5.905zm-3.903-.501a.25.25 0 0 1 .25.25v2.431a.25.25 0 0 1-.25.25H5.837a.25.25 0 0 1-.251-.25v-2.43a.25.25 0 0 1 .25-.251zm-4.15 0a.25.25 0 0 1 .251.25v2.431a.25.25 0 0 1-.25.25H1.682a.25.25 0 0 1-.25-.25v-2.43a.25.25 0 0 1 .25-.251zm3.9.5h-2.22v1.93h2.22v-1.93zm-4.15 0H1.934v1.93h2.224v-1.93zm4.4-4.476a.25.25 0 0 1 .25.25v2.431a.25.25 0 0 1-.25.251H5.837a.25.25 0 0 1-.251-.25V6.266a.25.25 0 0 1 .25-.25zm-4.15 0a.25.25 0 0 1 .251.25v2.431a.25.25 0 0 1-.25.251H1.682a.25.25 0 0 1-.25-.25V6.266a.25.25 0 0 1 .25-.25zm8.304 0a.25.25 0 0 1 .25.25v2.431a.25.25 0 0 1-.25.251H9.986a.25.25 0 0 1-.25-.25V6.266a.25.25 0 0 1 .25-.25zm-4.405.501h-2.22v1.93h2.22v-1.93zm-4.15 0H1.935v1.93h2.224v-1.93zm8.304 0h-2.224v1.93h2.224v-1.93zm.25-5.086a.25.25 0 0 1 .251.25v3.152a.25.25 0 0 1-.25.25H1.682a.25.25 0 0 1-.25-.25V1.683a.25.25 0 0 1 .25-.25zm-.25.502H1.934v2.65H12.46v-2.65z'
          stroke='#443A2C'
          strokeWidth='.1'
          fill='none'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)

export default paymentsCalculator
