import React, { createContext, useState, useCallback, Dispatch, SetStateAction } from 'react'
import { servicesType } from '../../../constants/interfaces.constant'
interface IChatMessageType {
  className?: string
  timestamp?: number | undefined
  title?: string
  text?: string | any
  owner: string
  subject: string
  createDate?: Date | undefined
  statusID?: number
}

export interface IDraftType {
  subject: string
  createDate: string
  statusID: number | undefined

  messages: IChatMessageType[]
}
interface IChatContext {
  messages: IChatMessageType[]
  addMessage: (message: IChatMessageType) => void
  setMessages: Dispatch<SetStateAction<IChatMessageType[]>>
  clearDraft: () => void
  updateLastMessageText: (text: string) => void
  updateMessageText: (index: number, text: string) => void
  applyDraft: () => void
  setDraft: () => void
  draft: IDraftType | undefined
}

export const ChatContext = createContext<IChatContext>({
  messages: [],
  addMessage: () => {},
  setMessages: () => {},
  clearDraft: () => {},
  updateLastMessageText: () => {},
  updateMessageText: () => {},
  applyDraft: () => {},
  setDraft: () => {},
  draft: {
    createDate: '',
    statusID: 0,
    subject: '',
    messages: []
  }
})

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessages] = useState<IChatMessageType[]>([])
  const [draftMessages, setDraftMessages] = useState<IDraftType>()

  const addMessage = useCallback((message: IChatMessageType) => {
    setMessages((prevMessages) => [...prevMessages, message])
    console.log('addMessage', message)
  }, [])

  const clearDraft = useCallback(() => {
    setDraftMessages(undefined)
    console.log('clearDraft')
  }, [])

  const applyDraft = useCallback(() => {
    if (draftMessages) {
      setMessages(draftMessages.messages)
      console.log('applyDraft', applyDraft)
    }
  }, [draftMessages])

  const setDraft = useCallback(() => {
    const draft = {
      createDate: new Date().toISOString(),
      statusID: 0,
      subject: messages[2]?.subject,
      messages: messages
    }
    setDraftMessages(draft)
  }, [messages])

  const updateLastMessageText = useCallback(
    (text: string) => {
      updateMessageText(messages.length - 1, text)
      console.log('updateLastMessageText', messages.length - 1)
    },
    [messages]
  )

  const updateMessageText = useCallback((index: number, text: string) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg, msgIndex) => {
        if (msgIndex !== index) {
          return msg
        }

        return {
          ...msg,
          text
        }
      })
    )
    console.log('updateMessageText', text)
  }, [])

  return (
    <ChatContext.Provider
      value={{
        messages,
        addMessage,
        setMessages,
        clearDraft,
        updateLastMessageText,
        updateMessageText,
        applyDraft,
        setDraft,
        draft: draftMessages
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
