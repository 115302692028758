import React from 'react'

function ConfirmationMobile() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.75 6.16667L5.41667 7.83333L8.75 4.5' stroke='white' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export default ConfirmationMobile
