import React from 'react'

function IconKey() {
  return (
    <svg
      style={{ margin: '0 0px 0 4px' }}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.7832 6.30765C19.7832 5.45611 19.6158 4.63088 19.2881 3.85109C18.9699 3.10001 18.5155 2.42547 17.9366 1.84661C17.3577 1.26775 16.6832 0.813274 15.9321 0.49514C15.1547 0.165047 14.3271 0 13.4756 0C12.624 0 11.7988 0.167439 11.019 0.49514C10.2679 0.813274 9.59338 1.26775 9.01452 1.84661C8.43566 2.42547 7.98119 3.10001 7.66305 3.85109C7.33296 4.62848 7.16791 5.45611 7.16791 6.30765C7.16791 7.37209 7.43581 8.41499 7.94531 9.34308L0.393823 16.8874C0.219208 17.062 0.164193 17.3275 0.271832 17.5787C0.29336 17.6313 0.329239 17.6791 0.369903 17.7198L2.06103 19.4109C2.1017 19.4516 2.14954 19.4851 2.20216 19.509C2.45332 19.6166 2.71883 19.564 2.89344 19.387L3.93874 18.3417L5.44329 19.8462C5.48396 19.8869 5.5318 19.9204 5.5844 19.9443C5.83556 20.052 6.10109 19.9994 6.27568 19.8223L7.94289 18.1551C7.98355 18.1144 8.01704 18.0666 8.04096 18.014C8.1486 17.7628 8.09598 17.4973 7.91897 17.3227L6.43833 15.8421L10.4377 11.8403C11.3634 12.3498 12.4087 12.6177 13.4731 12.6177C14.3247 12.6177 15.1499 12.4503 15.9297 12.1226C16.6808 11.8044 17.3553 11.3499 17.9342 10.7711C18.513 10.1923 18.9675 9.51772 19.2857 8.76661C19.6158 7.98443 19.7832 7.1592 19.7832 6.30765ZM15.6858 8.52024C15.095 9.11106 14.308 9.43637 13.4732 9.43637C12.6384 9.43637 11.8514 9.11106 11.2606 8.52024C10.6698 7.92942 10.3421 7.14246 10.3421 6.30765C10.3421 5.47046 10.6674 4.68589 11.2582 4.09507C11.849 3.50425 12.636 3.17894 13.4708 3.17894C14.3056 3.17894 15.0926 3.50425 15.6834 4.09507C16.2742 4.68589 16.5995 5.47285 16.5995 6.30765C16.6043 7.14246 16.279 7.92942 15.6858 8.52024Z'
        fill='#00468A'
      />
    </svg>
  )
}

export default IconKey
