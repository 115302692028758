import React from 'react'

function PdfIcons() {
  return (
    <svg width='19' height='22' viewBox='0 0 19 22' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='ra8e4on9pa'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='m0c5y9h16b'>
          <path d='M409 20c13.807 0 25 11.193 25 25v310c0 13.807-11.193 25-25 25H25c-13.807 0-25-11.193-25-25V45c0-13.807 11.193-25 25-25h384z' />
        </clipPath>
        <clipPath id='jf0ixoijac'>
          <path d='m12.579 0 4.838 5.5V20c0 1.105-.867 2-1.936 2H1.935C.866 22 0 21.105 0 20V2C0 .895.866 0 1.935 0H12.58zm-1.923.917H1.891c-.538 0-.974.451-.974 1.008v18.15c0 .557.436 1.008.974 1.008h13.635c.538 0 .974-.451.974-1.008V6.967h-4.87c-.538 0-.974-.452-.974-1.009V.917zM6.053 13.15c.272.232.424.566.416.914.015.346-.113.684-.358.94a1.797 1.797 0 0 1-1.27.415c-.1.006-.2.006-.3 0v1.419h-.874v-3.913c.395-.062.795-.09 1.195-.085.423-.035.845.075 1.19.31zm7.857-.317v.743h-1.512v.914h1.411v.737H12.4v1.611h-.892v-4.005h2.403zm-3.935.452c.425.367.651.914.61 1.476a2.049 2.049 0 0 1-.6 1.578 2.47 2.47 0 0 1-1.67.492c-.31.002-.62-.017-.928-.059v-3.85a6.764 6.764 0 0 1 1.108-.084 2.207 2.207 0 0 1 1.48.447zm-1.274.235-.118.002a1.597 1.597 0 0 0-.37.034v2.562c.093.01.188.01.282 0 .738.01 1.221-.428 1.226-1.357a1.08 1.08 0 0 0-.906-1.23l-.114-.011zm-3.77 0a1.49 1.49 0 0 0-.364.034v1.19c.098.01.197.01.295 0l-.026.005a.705.705 0 0 0 .142.01c.37-.015.657-.31.643-.66 0-.36-.248-.58-.69-.58zM12.186.916h-.459v4.856h4.584L12.186.917z' />
        </clipPath>
      </defs>
      <g clipPath='url(#ra8e4on9pa)' transform='translate(-99 -1362)'>
        <g clipPath='url(#m0c5y9h16b)' transform='translate(78 1281)'>
          <g clipPath='url(#jf0ixoijac)' transform='translate(21.833 81)'>
            <path fill='#242424' d='M0 0h17.417v22H0V0z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PdfIcons
