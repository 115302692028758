import React from 'react'
import Router from './Router/Router'

const App: React.FC = () => {
  return (
    <>
      <Router />
    </>
  )
}

export default App
