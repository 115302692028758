const formatText = (text) => {
  if (!text) {
    return ''
  }

  if (typeof text !== 'string' || !text) {
    return null
  }

  const regex = /(http[s]?:\/\/\S+)/g

  const matches = text.match(regex) || []
  let html = text

  for (const match of matches) {
    html = html.replace(match, `<a className="file-link" href="${match}" target="_blank">להורדת קובץ </a>`)
  }

  return html
}

export default formatText
