import React from 'react'

function UpcomingPaymentsBubble() {
  return (
    <svg width='206' height='180' viewBox='0 0 206 180' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='vr5olqggga'>
          <path d='M1366 0v3517H0V0h1366z' />
        </clipPath>
        <clipPath id='035gh1y8cb'>
          <path d='M206 83.503c.131 63.3-50.566 96.222-108.93 96.495C37.882 180.275-.136 151.74 0 90.306c.104-46.503 40.534-49.087 94.12-77.687C163.193-22.782 205.868 21.23 206 83.503z' />
        </clipPath>
        <clipPath id='aesos9jejc'>
          <path d='M206 0v180H0V0h206z' />
        </clipPath>
      </defs>
      <g clipPath='url(#vr5olqggga)' transform='translate(-283 -1660)'>
        <g clipPath='url(#035gh1y8cb)' transform='matrix(1 0 0 -1 283 1840)'>
          <g clipPath='url(#aesos9jejc)'>
            <path fill='#FFF' d='M0 0h206v180H0V0z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default UpcomingPaymentsBubble
