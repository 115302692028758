import React from 'react'

function Attach() {
  return (
    <svg
      style={{ cursor: 'pointer', zIndex: 0 }}
      width='9'
      height='15'
      viewBox='0 0 9 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.17237 15C3.02042 15 2.03704 14.604 1.22223 13.8121C0.407409 13.0202 0 12.0501 0 10.9016V2.93976C0 2.1255 0.288234 1.43197 0.864703 0.859182C1.44116 0.286394 2.13613 0 2.94963 0C3.77539 0 4.47407 0.286394 5.04568 0.859182C5.61729 1.43197 5.9031 2.13163 5.9031 2.95817V10.2311C5.9031 10.7179 5.73627 11.1311 5.40262 11.4709C5.06895 11.8107 4.658 11.9806 4.16977 11.9806C3.68155 11.9806 3.27135 11.7992 2.93915 11.4363C2.60696 11.0735 2.44086 10.641 2.44086 10.1391V3.31942C2.44086 3.20514 2.4817 3.10715 2.56338 3.02547C2.64506 2.94379 2.74305 2.90296 2.85733 2.90296C2.97016 2.90296 3.06851 2.94379 3.15239 3.02547C3.23628 3.10715 3.27822 3.20514 3.27822 3.31942V10.1943C3.27822 10.458 3.36468 10.6828 3.53762 10.8687C3.71053 11.0547 3.92214 11.1477 4.17244 11.1477C4.42274 11.1477 4.6342 11.0578 4.80683 10.8779C4.97944 10.6981 5.06574 10.4825 5.06574 10.2311V2.93976C5.06574 2.35085 4.86024 1.85396 4.44923 1.44908C4.03822 1.04421 3.53826 0.841772 2.94935 0.841772C2.36044 0.841772 1.86048 1.04421 1.44947 1.44908C1.03846 1.85396 0.832957 2.35085 0.832957 2.93976V10.9369C0.832957 11.8423 1.1601 12.607 1.81439 13.231C2.46868 13.855 3.25436 14.167 4.17143 14.167C5.10076 14.167 5.88969 13.8521 6.53822 13.2222C7.18674 12.5924 7.511 11.8188 7.511 10.9016V3.31942C7.511 3.20514 7.55184 3.10715 7.63352 3.02547C7.7152 2.94379 7.81288 2.90296 7.92655 2.90296C8.04023 2.90296 8.13895 2.94379 8.22272 3.02547C8.30648 3.10715 8.34836 3.20514 8.34836 3.31942V10.8832C8.34836 12.0317 7.94035 13.0049 7.12433 13.8029C6.30831 14.601 5.32432 15 4.17237 15Z'
        fill='#473B33'
      />
    </svg>
  )
}

export default Attach
