import React, { useEffect, useState } from 'react'
import { Button, styled, Divider, Box, Paper, InputBase, IconButton } from '@mui/material'
import { Colors } from '../../../constants/styles.constants'
import { Attach } from '../../../constants/icons.constants'
import { useChatHttp } from '../../../hooks/useChatHttp/useChatHttp'
import { servicesType } from '../../../constants/interfaces.constant'

type Props = {
  error?: any
  formData?: any
  handleInputData?: any
  allQuestionsData?: servicesType[]
  callID?: any
  selectedFile?: any
  setSelectedFile?: any
  fileColor?: any
  setFileColor?: any
}

function FormInput({
  fileColor,
  setFileColor,
  selectedFile,
  setSelectedFile,
  callID,
  handleInputData,
  error,
  formData
}: Props) {
  const [text, setText] = useState('')

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    setFileColor('#ccdded')
  }

  const handleFileDelete = () => {
    setSelectedFile(null)
    setFileColor('')
  }
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formElement = event.currentTarget as HTMLFormElement
    const { text, file } = Object.fromEntries(new FormData(formElement))

    if (file?.name) {
      handleInputData({ text, file })
    } else {
      handleInputData({ text })
    }

    formElement.reset()
  }
  return (
    <>
      <FormWrapper>
        <Paper
          component='form'
          onSubmit={submitForm}
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: 50,
            height: 50,
            margin: 'auto'
          }}
        >
          {callID && (
            <React.Fragment>
              <Box
                sx={{
                  position: 'relative',
                  pt: 0.5,
                  px: 1,
                  pb: 0.5,
                  backgroundColor: fileColor,
                  borderRadius: '7px'
                }}
              >
                <FileInput name='file' className='file-to-send' type='file' style={{}} onChange={handleFileChange} />

                {selectedFile && <IconWrapper onClick={handleFileDelete}> x</IconWrapper>}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 6,
                    left: 0,
                    right: 16,
                    width: 9,
                    height: 15,
                    zIndex: 0
                  }}
                >
                  <Attach />
                </Box>
              </Box>
              <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
            </React.Fragment>
          )}

          <InputBase
            name='text'
            sx={{ ml: 1, flex: 1, borderRadius: 8, padding: ' 8px' }}
            placeholder='הודעה חדשה'
            inputProps={{ 'aria-label': 'הודעה חדשה' }}
            value={formData}
          />
          <Button
            variant='contained'
            style={{
              borderRadius: 20,
              padding: '3px 24px',
              background: Colors.blue
            }}
            type='submit'
          >
            שליחה
          </Button>

          <IconButton color='primary' sx={{ p: '10px' }} aria-label='directions'></IconButton>
        </Paper>
        <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
      </FormWrapper>
    </>
  )
}

export default FormInput

const FormWrapper = styled('div')({
  display: 'block',
  width: '90%',
  margin: '16px auto'
})

const FileInput = styled('input')({
  position: 'relative',
  width: 23,
  zIndex: 99
})
const IconWrapper = styled('div')({
  position: 'absolute',
  fontSize: 11,
  top: 0,
  left: -1,
  zIndex: 999,
  cursor: 'pointer',
  background: '#A29C96',
  display: 'initial',
  padding: '0px 5px',
  borderRadius: '50%'
})
