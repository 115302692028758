import React from 'react'
import Chatbackground from '../../../assets/images/Chatbackground.png'
import styled from 'styled-components'
import ChatCard from '../ChatOnLine/ChatCard/ChatCard'
import FormInput from '../FormInput/FormInput'
import { useChatScroll } from '../../../hooks/useChatScroll/useChatScroll'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import { theme } from '../../../constants/theme.constants'
import { servicesType } from '../../../constants/interfaces.constant'
import { Box } from '@mui/material'
import { isSameDay, isToday } from 'date-fns'

type Props = {
  handleInputData?: any
  visibleForm?: boolean
  error?: string
  fileResponse?: any
  allQuestionsData?: servicesType[]
  selectedFile?: any
  setSelectedFile?: any
  setFileColor?: any
  fileColor?: any
  callID?: any
}
function ChatBackgroundSreen({
  allQuestionsData,
  fileResponse,
  error,
  handleInputData,
  visibleForm,
  selectedFile,
  setSelectedFile,
  setFileColor,
  fileColor
}: Props) {
  const { messages, callID } = useChatMessages()
  const ref = useChatScroll()
  const sortedMessages = messages && messages.slice().sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0))
  return (
    <ChatBackground>
      <div
        className='sreen-chat'
        ref={ref}
        style={{
          width: '100%',
          overflow: 'scroll',
          scrollbarWidth: 'none'
        }}
      >
        {messages &&
          sortedMessages
            .map((item: any, index: any) => {
              return (<>
                {(index === 0 ||
                  (index > 0 &&
                    !isSameDay(new Date(item?.timestamp), new Date(sortedMessages[index - 1]?.timestamp)))) &&
                  <Box sx={{ textAlign: 'center', marginRight: '3rem' }}>
                    {isToday(new Date(item?.timestamp)) ? 'היום' : new Date(item?.timestamp).toLocaleDateString()}
                  </Box>}
                <ChatCard
                  url={item?.url}
                  className={item?.className}
                  fileResponse={fileResponse}
                  key={index}
                  item={item}
                  subject={item?.subject}
                  title={item?.title}
                  owner={item?.owner}
                  text={item?.text}
                  timestamp={item?.timestamp}
                  filename={item?.filename}
                />
              </>)
            }
            )}
      </div>

      {(visibleForm || callID) && (
        <FormInput
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          callID={callID}
          allQuestionsData={allQuestionsData}
          error={error}
          handleInputData={handleInputData}
          setFileColor={setFileColor}
          fileColor={fileColor}
        />
      )}
    </ChatBackground>
  )
}

export default ChatBackgroundSreen

const ChatBackground = styled('div')({
  position: 'relative',
  backgroundImage: `url(${Chatbackground})`,
  width: '100%',
  height: '80svh',
  overflow: 'auto',
  overflowY: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  flexDirection: 'column',
  [theme.breakpoints.down('xl')]: {
    height: '73svh'
  },
  [theme.breakpoints.down('md')]: {
    height: '82svh'
  }
})
