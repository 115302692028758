import React from 'react'

const MainLogoIcon = () => (
  <svg
    width='107'
    height='38'
    viewBox='0 0 107 38'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <defs>
      <path id='vxby9vwy0a' d='M0 38h107V0H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path
        d='M52.73 19.48v1.315c0 1.803-1.477 3.27-3.293 3.27-1.815 0-3.292-1.467-3.292-3.27V15.56H44.17v5.234c0 2.89 2.358 5.233 5.267 5.233 2.91 0 5.268-2.343 5.268-5.233V19.48h-1.976z'
        fill='#FFF'
      />
      <mask id='zplgvpntnb' fill='#fff'>
        <use xlinkHref='#vxby9vwy0a' />
      </mask>
      <path
        fill='#FFF'
        mask='url(#zplgvpntnb)'
        d='M52.732 17.521h1.973v-1.96h-1.973zM56.677 26.028v-1.925h5.895c.655 0 1.188-.529 1.188-1.18 0-.561-.42-1.06-.978-1.16h-.016a2.853 2.853 0 0 1-.194.007h-5.895v-1.95h5.895c.062 0 .126.001.195.006h.007l.008-.001c.558-.1.978-.598.978-1.16 0-.65-.533-1.18-1.188-1.18h-5.895v-1.924h5.895c1.723 0 3.125 1.393 3.125 3.104a3.14 3.14 0 0 1-.812 2.087l-.04.042.04.043c.516.568.812 1.328.812 2.087 0 1.711-1.402 3.104-3.125 3.104h-5.895zM71.283 16.276h-3.782v-.685h8.33v.685h-3.782v9.752h-.766zM84.97 25.342v.686H77.72V15.591h7.024v.685h-6.258v4.116h5.597v.67h-5.597v4.28zM86.695 20.81c0-3.043 2.327-5.294 5.448-5.294 1.486 0 2.821.477 3.737 1.446l-.48.492c-.9-.88-1.996-1.237-3.227-1.237-2.686 0-4.712 1.968-4.712 4.592 0 2.625 2.026 4.593 4.712 4.593 1.23 0 2.326-.373 3.227-1.252l.48.492c-.916.969-2.251 1.46-3.737 1.46-3.121 0-5.448-2.25-5.448-5.293M107 15.59v10.438h-.765v-4.965H99.21v4.965h-.765V15.591h.765v4.8h7.024v-4.8zM105.766 35.342h-2.843c.145.457.582.745 1.08.745.37 0 .707-.155.982-.44l.537.465c-.256.366-.837.72-1.518.72-1.075 0-1.894-.825-1.894-1.825 0-1.006.825-1.826 1.838-1.826a1.845 1.845 0 0 1 1.818 2.161zM100.938 38h6.025v-5.986h-6.025V38z'
      />
      <path
        d='M103.948 33.926c-.5 0-.906.326-1.033.777l2.081.01a1.085 1.085 0 0 0-1.048-.787M81.999 35.53c0-.39-.262-.635-.624-.635s-.624.245-.624.636c0 .39.262.635.624.635s.624-.245.624-.635m.786 0c0 .775-.566 1.374-1.253 1.374-.35 0-.608-.12-.78-.317v.244h-.787v-3.645h.786v1.286c.173-.197.43-.317.781-.317.687 0 1.253.599 1.253 1.375M85.615 34.229l-.896 2.525c-.294.828-.76 1.157-1.494 1.12v-.729c.367 0 .54-.115.655-.432l-1.043-2.484h.865l.582 1.593.493-1.593h.838zM99.073 36.686h-.844v-1.735a.94.94 0 0 0-.937-.944.94.94 0 0 0-.938.944v1.735h-.844v-1.735c0-.975.8-1.77 1.782-1.77.98 0 1.781.795 1.781 1.77v1.735zM94.279 38h6.025v-5.986h-6.025V38zM90.636 33.976c-.569 0-1.025.46-1.025 1.03a1.025 1.025 0 1 0 2.05 0c0-.57-.457-1.03-1.025-1.03'
        fill='#FFF'
        mask='url(#zplgvpntnb)'
      />
      <path
        d='M90.636 36.832a1.834 1.834 0 0 1-1.838-1.825 1.83 1.83 0 0 1 1.838-1.826c1.012 0 1.837.82 1.837 1.826a1.834 1.834 0 0 1-1.837 1.825zM87.623 38h6.025v-5.986h-6.025V38zM6.01 16.984h2.087v-1.117H6.01v1.117zm0-1.523h2.087V8.939c-.722 0-1.421.095-2.087.271v6.25zm-2.987 1.523H5.11v-1.117H3.023v1.117zm0-6.267v4.744H5.11V9.507c-.759.3-1.461.709-2.087 1.21zM.036 16.984h2.087v-1.117H.036v1.117zm.11-1.523h1.977v-3.907a8 8 0 0 0-1.976 3.907zm13.572 6.44a.476.476 0 0 0-.954 0 .476.476 0 0 0 .954 0zm1.142 0c0 .887-.726 1.608-1.62 1.608-.892 0-1.618-.721-1.618-1.609 0-.887.726-1.609 1.619-1.609s1.62.722 1.62 1.61zm1.287 0c0-1.593-1.304-2.888-2.906-2.888s-2.906 1.295-2.906 2.887 1.304 2.888 2.906 2.888 2.906-1.296 2.906-2.888zm1.143 0c0 2.221-1.813 4.022-4.05 4.022-2.235 0-4.048-1.801-4.048-4.023 0-2.221 1.813-4.022 4.049-4.022 2.236 0 4.049 1.8 4.049 4.022zm4.948-4.023-4.049 4.496h1.531v3.674h5.035v-3.674h1.531l-4.048-4.496zm-.856-4.066h1.712v-1.7h-1.712v1.7zm-.695.69h3.102V11.42h-3.102v3.083zm-.902.896h4.905v-4.873h-4.905v4.873zm-.695.69h6.295V9.835H19.09v6.255zm-.9.896h8.096V8.94H18.19v8.045zm20.137-3.205a7.994 7.994 0 0 0-.614-1.139l-4.372 4.344h1.76l3.226-3.205zm-8.266-4.84h-2.875v2.857l2.875-2.857zm6.564 8.045h2.372c0-.72-.097-1.418-.276-2.082l-2.096 2.082zm-.438-6.09-6.13 6.09h1.76l5.247-5.214a8.128 8.128 0 0 0-.877-.876zm-2.01-1.266-6.991 6.945v.41h1.346l6.785-6.74a8.09 8.09 0 0 0-1.14-.615zm-6.991 5.43 5.868-5.829a8.11 8.11 0 0 0-1.497-.261l-4.371 4.343v1.748zm3.714 2.814h-3.714v8.045H30.9c4.471 0 8.097-3.602 8.097-8.045H30.9zM18.19 8.045h8.096C26.286 3.602 22.661 0 18.19 0v8.045zM8.096 17.878H0c0 4.443 3.625 8.045 8.097 8.045v-8.045zM17.29 2.514c-3.075 0-5.567 2.476-5.567 5.531h1.265c0-2.36 1.926-4.274 4.302-4.274V2.514zm0 2.514a3.027 3.027 0 0 0-3.037 3.017h1.265c0-.972.793-1.76 1.772-1.76V5.028zm0-5.028c-4.472 0-8.098 3.602-8.098 8.045h1.266c0-3.749 3.058-6.788 6.832-6.788V0zM9.192 16.984h8.098V8.939H9.192v8.045z'
        fill='#FFF'
        mask='url(#zplgvpntnb)'
      />
    </g>
  </svg>
)

export default MainLogoIcon
