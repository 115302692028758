import React from 'react'

const SideMenuBackground = () => (
  <svg width='285' height='676' viewBox='0 0 285 676' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <clipPath id='w5gu1to55a'>
        <path d='M1680 0v2959H0V0h1680z' />
      </clipPath>
      <clipPath id='vsw62fz6hc'>
        <path d='M233.072 92.632c179.712-205.79 398.107-39.462 452.863 213.271 2.29 10.594-5.745 20.446-15.076 18.317a526.569 526.569 0 0 0-39.673-7.478c-58.107-8.709-113.58-8.67-166.427-5.741-52.963 2.928-103.642 8.751-153.175 14.336-49.513 5.613-138.966 21.706-243.127-12.847-24.468-4.79-43.177-10.275-56.127-16.454-19.426-9.268-12.362-13.658-2.818-13.612 66.89.316 106.42-55.652 223.56-189.792z' />
      </clipPath>
      <filter id='qrwrjjaxbb' x='-1.728%' y='-3.509%' filterUnits='userSpaceOnUse' width='102.304%' height='104.678%'>
        <feGaussianBlur stdDeviation='2' in='SourceAlpha' result='i2' />
        <feOffset in='i2' result='i3' />
        <feColorMatrix values='0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1 0' in='i3' />
      </filter>
    </defs>
    <g clipPath='url(#w5gu1to55a)' transform='translate(-1395)'>
      <path
        d='M233.072 92.632C115.932 226.772 76.402 282.74 9.512 282.424c-9.544-.046-16.608 4.344 2.818 13.612 12.95 6.179 31.659 11.663 56.127 16.454 104.16 34.553 193.614 18.46 243.127 12.847 49.533-5.585 100.212-11.408 153.175-14.336 52.847-2.929 108.32-2.968 166.427 5.741a526.569 526.569 0 0 1 39.673 7.478c9.33 2.129 17.366-7.723 15.076-18.317C631.18 53.17 412.784-113.159 233.072 92.633z'
        filter='url(#qrwrjjaxbb)'
        transform='rotate(-90 1036.665 -362.5)'
      />
      <g clipPath='url(#vsw62fz6hc)' transform='rotate(-90 1036.665 -362.5)'>
        <path fill='#E0E1DB' d='M0 0h674.165v280.835H0V0z' />
      </g>
    </g>
  </svg>
)

export default SideMenuBackground
