import React, { useState } from 'react'
/*components*/
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
/*styles*/
import { styled } from '@mui/material'
import { DimanicText, ItemText } from '../../../../constants/styled.components.constants'
import { Info } from '../../../../constants/icons.constants'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import { theme } from '../../../../constants/theme.constants'

interface IContractSummary {
  estimatedDate: string | undefined
  lotNumber: string | undefined
  priceWithTax: string | undefined | any
  signDate: string | undefined
}
function ContractSummary({ estimatedDate, lotNumber, priceWithTax, signDate }: IContractSummary) {
  const [openTooltipKey, setOpenTooltipKey] = useState<string | null>(null)
  const tooltips = useAppSelector((state) => state.global?.tooltipsList)

  const getTooltipTextValueByKey = (tooltipKey: string) => {
    return tooltips?.find((tooltip) => tooltip.key === tooltipKey)?.value || ''
  }

  const handleTooltipToggle = (tooltipKey: string) => {
    setOpenTooltipKey((prevKey) => (prevKey === tooltipKey ? null : tooltipKey))
  }

  const handleTooltipOpen = (tooltipKey: string) => {
    setOpenTooltipKey(tooltipKey)
  }

  const handleTooltipClose = () => {
    setOpenTooltipKey(null)
  }

  return (
    <>
      <WrapperInner className='space-between children-cardBody'>
        <ItemText> תאריך חתימה</ItemText>
        <DimanicText>{signDate}</DimanicText>
      </WrapperInner>
      <WrapperInner>
        <ItemText> תאריך מסירה משוער</ItemText>
        <Tooltip
          dir='rtl'
          open={openTooltipKey === 'contract_delivery_date'}
          onClose={() => setOpenTooltipKey(null)}
          title={getTooltipTextValueByKey('contract_delivery_date')}
          onMouseEnter={() => handleTooltipOpen('contract_delivery_date')}
          onMouseLeave={handleTooltipClose}
          onClick={() => handleTooltipToggle('contract_delivery_date')}
          onMouseDown={(e) => {
            e.preventDefault()
            handleTooltipToggle('contract_delivery_date')
          }}
          componentsProps={{
            tooltip: {
              sx: {
                marginTop: '0px !important',
                maxWidth: '200px'
              }
            }
          }}
        >
          <IconButton style={{ padding: '0' }}>
            <DimanicText className='small-gap'>{estimatedDate}</DimanicText> <Info />
          </IconButton>
        </Tooltip>
      </WrapperInner>
      <WrapperInner>
        <ItemText> מחיר דירה כולל מע״מ</ItemText>
        <Tooltip
          dir='rtl'
          open={openTooltipKey === 'contract_apartment_price'}
          onClose={() => setOpenTooltipKey(null)}
          title={getTooltipTextValueByKey('contract_apartment_price')}
          onMouseEnter={() => handleTooltipOpen('contract_apartment_price')}
          onMouseLeave={handleTooltipClose}
          onClick={() => handleTooltipToggle('contract_apartment_price')}
          onMouseDown={(e) => {
            e.preventDefault()
            handleTooltipToggle('contract_apartment_price')
          }}
          componentsProps={{
            tooltip: {
              sx: {
                marginTop: '0px !important',
                maxWidth: '200px'
              }
            }
          }}
        >
          <IconButton style={{ padding: '0' }} onClick={() => handleTooltipToggle('contract_apartment_price')}>
            <DimanicText className='small-gap'>{parseFloat(priceWithTax).toLocaleString()} ₪</DimanicText> <Info />
          </IconButton>
        </Tooltip>
      </WrapperInner>
      <WrapperInner>
        {lotNumber && (
          <>
            <ItemText> ממגרש</ItemText>
            <DimanicText>{lotNumber}</DimanicText>
          </>
        )}
      </WrapperInner>
    </>
  )
}

export default ContractSummary
const WrapperInner = styled('div')({
  width: '25%',
  padding: '10px 0rem 0 0',
  '& .small-gap': {
    padding: '0 0 0 6px'
  },
  '& .space-between': {
    padding: '0 8px'
  },
  '& .space-between .MuiButtonBase-root-JvZdr.lcfWbe:hover': {
    backgroundColor: 'none'
  },
  [theme.breakpoints.down('md')]: {
    width: '50%'
  }
})
