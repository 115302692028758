import React from 'react'

interface ChevronLeftProps {
  width?: string
  height?: string
  fill?: string
}

const ChevronLeft: React.FC<ChevronLeftProps> = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '15'}
      height={height || '24'}
      viewBox='0 0 15 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2387 24C13.536 24 13.8332 23.8956 14.0599 23.6901C14.5151 23.2775 14.5133 22.6122 14.0581 22.2013L2.80412 12.0407L14.0599 1.79596C14.5151 1.38333 14.5133 0.718089 14.0581 0.307153C13.6028 -0.103784 12.8689 -0.1021 12.4174 0.310521L0.340406 11.3014C0.121161 11.5001 0.000391483 11.7679 0.000391483 12.0475C0.000391483 12.3254 0.12302 12.5931 0.342264 12.7902L12.4193 23.6935C12.646 23.8973 12.9432 24 13.2387 24'
        fill={fill || '#453A2D'}
      />
    </svg>
  )
}

export default ChevronLeft
