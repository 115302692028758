import React from 'react'

function ContractIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='2tm9sw21ea'>
          <path d='M1680 0v2959H0V0h1680z' />
        </clipPath>
        <clipPath id='gek2f81kjb'>
          <path d='M20.167 0A1.835 1.835 0 0 1 22 1.833v1.834a.734.734 0 0 1-.733.733h-2.2v15.767A1.835 1.835 0 0 1 17.233 22h-15.4A1.835 1.835 0 0 1 0 20.167v-1.834c0-.404.329-.733.733-.733h2.2V1.833A1.835 1.835 0 0 1 4.767 0h15.4zM15.4 18.333H.733v1.834c.001.607.493 1.099 1.1 1.1h13.935a1.824 1.824 0 0 1-.368-1.1v-1.834zm3.31-17.6H4.766c-.608.001-1.1.493-1.1 1.1V17.6H15.4c.405 0 .733.329.733.733v1.834a1.1 1.1 0 0 0 2.2 0V1.833c.002-.398.134-.784.376-1.1zm-2.577 10.634c.405 0 .733.328.734.733v2.933a.734.734 0 0 1-.734.734H13.2a.734.734 0 0 1-.733-.734V12.1c0-.405.328-.733.733-.733h2.933zm0 .733H13.2v2.933h2.934V12.1zm-4.766 1.833a.367.367 0 0 1 0 .734H5.5a.367.367 0 0 1 0-.734h5.867zm-2.2-1.466a.367.367 0 1 1 0 .733H5.5a.367.367 0 0 1 0-.733h3.667zm2.2 0a.367.367 0 1 1 0 .733h-.734a.367.367 0 0 1 0-.733h.734zM15.4 5.5a.367.367 0 0 1 0 .733h-2.2v2.934h2.933v-.734a.367.367 0 0 1 .734 0v.734a.734.734 0 0 1-.734.733H13.2a.734.734 0 0 1-.733-.733V6.233c0-.404.328-.733.733-.733h2.2zm-4.033 2.567a.367.367 0 0 1 0 .733H5.5a.367.367 0 0 1 0-.733h5.867zm6.121-2.455c.142.141.144.37.005.514l-2.2 2.2a.366.366 0 0 1-.519 0l-.733-.733a.367.367 0 0 1 .518-.519l.474.474 1.941-1.94a.367.367 0 0 1 .514.004zM9.167 6.6a.367.367 0 0 1 0 .733H5.5a.367.367 0 0 1 0-.733h3.667zm2.2 0a.367.367 0 0 1 0 .733h-.734a.367.367 0 0 1 0-.733h.734zm8.8-5.867a1.1 1.1 0 0 0-1.1 1.1v1.834h2.2V1.833a1.1 1.1 0 0 0-1.1-1.1zM13.2 2.2a.367.367 0 0 1 0 .733H5.5a.367.367 0 0 1 0-.733h7.7zm3.3 0a.367.367 0 0 1 0 .733h-1.467a.367.367 0 0 1 0-.733H16.5z' />
        </clipPath>
      </defs>
      <g clipPath='url(#2tm9sw21ea)' transform='translate(-1393 -1940)'>
        <g clipPath='url(#gek2f81kjb)' transform='translate(1394 1941)'>
          <path fill='#FFF' d='M0 0h22v22H0V0z' />
        </g>
      </g>
    </svg>
  )
}

export default ContractIcon
